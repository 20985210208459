.form-builder-container .grid-wrapper {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.form-builder-container .tri-grid {
    display: grid;
    /*grid-template-columns: 20% 50% 30%;*/
    grid-template-columns: 20% 80%;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
}

.form-builder-container .current-tab {
    background-color: rgb(250, 250, 250);
}

.form-builder-container .column-tabs button {
    outline: none;
    border-left: #bfbfbf 1px solid;
    border-top: #bfbfbf 1px solid;
    box-shadow: inset 0px -10px 10px -10px #888;
}

    .form-builder-container .column-tabs button:last-of-type {
        border-right: #bfbfbf 1px solid;
    }

    .form-builder-container .column-tabs button.current-tab {
        box-shadow: 0px -10px 10px -10px #888, 10px -10px 10px -10px #888, -10px -10px 10px -10px #888;
        position: relative;
    }

.form-builder-container .tri-grid--full-center {
    grid-column: span 3;
    text-align: center;
}

.form-builder-container .left-column {
    text-align: left;
    font-weight: 600;
    color: black;
    margin-right: 5px;
}

    .form-builder-container .left-column button {
        width: 100%;
        padding: 10px;
    }

        .form-builder-container .left-column button:hover {
            background-color: #bbb;
        }

    .form-builder-container .left-column h3 {
        text-align: center;
        padding-bottom: 2px;
    }

    .form-builder-container .left-column .current-component {
        padding: 5px 10px;
        display: inline;
    }

    .form-builder-container .left-column .current-component__drag-icon {
        margin-right: 10px;
        margin-bottom: 4%;
    }

    .form-builder-container .left-column .current-component__data {
        display: inline-block;
    }

    .form-builder-container .left-column .current-component__manage {
        float: right;
    }

    .form-builder-container .left-column .current-component__edit {
        padding-left: 5px;
        padding-right: 5px;
    }

        .form-builder-container .left-column .current-component__edit:hover {
            cursor: pointer;
            color: #80BA54;
        }

    .form-builder-container .left-column .current-component__delete {
        padding-left: 5px;
        padding-right: 5px;
    }

        .form-builder-container .left-column .current-component__delete:hover {
            cursor: pointer;
            color: red;
        }

.form-builder-container .center-column {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

    .form-builder-container .center-column .component-type-choice select {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .form-builder-container .center-column .component-type-choice .component-type-ddl {
        width: 50%;
    }

    .form-builder-container .center-column .component-type-choice .component-type-choice--grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        grid-gap: 10px;
    }

    .form-builder-container .center-column .component-type-choice .component-type-button {
        padding: 0;
        background-color: transparent;
        opacity: 0.8;
    }

        .form-builder-container .center-column .component-type-choice .component-type-button:hover {
            opacity: 1;
        }

        .form-builder-container .center-column .component-type-choice .component-type-button span {
            display: block;
        }

        .form-builder-container .center-column .component-type-choice .component-type-button img {
            width: 125px;
            height: 80px;
        }

    .form-builder-container .center-column .intro-data span {
        font-size: x-large;
    }

    .form-builder-container .center-column .intro-data .explination-container {
        padding-left: 7%;
        padding-right: 7%;
    }

    .form-builder-container .center-column .existing-component {
        padding-left: 25%;
        padding-right: 25%;
    }

        .form-builder-container .center-column .existing-component button {
            width: 100%;
        }

            .form-builder-container .center-column .existing-component button:hover {
                background-color: #bbb;
            }

.form-builder-container .right-column {
    text-align: left;
}

    .form-builder-container .right-column h3 {
        text-align: center;
    }

.form-builder-container .page-title h2 {
    font-weight: bold;
}

.form-builder-container .selected-component {
    color: yellow;
}
