.form-builder-submissions-container {
    width: 90%;
    margin: auto;
}

    .form-builder-submissions-container .page-title {
        text-align: center;
    }

    .form-builder-submissions-container .view-btn:hover {
        cursor: pointer;
        color: #007bff;
    }

    .form-builder-submissions-container .delete-btn:hover {
        cursor: pointer;
        color: red;
    }

    .form-builder-submissions-container .form-submission-mass-range-container {
        margin-bottom: 1%;
    }

        .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 10px;
        }

            .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .field-box {
            
            }

                .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .field-box label {
                    display: block;
                    font-weight: 600;
                }

                .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .field-box select {
                    width: 100%;
                    height: 40px;
                }

                .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .field-box input {
                    width: 100%;
                    height: 40px;
                }

            .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .mass-submit-buttons {
            }

                .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .mass-submit-buttons .export {
                    background-color: #007bff;
                    color: white;
                    margin: auto 5px;
                    border-radius: 5px;
                    margin-top: 14px;
                }

                .form-builder-submissions-container .form-submission-mass-range-container .form-submission-mass-range-form .mass-submit-buttons .delete {
                    background-color: red;
                    color: white;
                    margin: auto 5px;
                    border-radius: 5px;
                    margin-top: 14px;
                }

