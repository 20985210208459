.pages-container {
    width: 90%;
    margin: auto;
}

.pages-table-wrapper table {
    width: 100%;
    margin: auto;
}

.pages-table-wrapper th {
    text-align: left;
}

.pages-table-wrapper tr {
}

.page-title {
    text-align: center;
}

.update-btn:hover {
    cursor: pointer;
    color: #80BA54;
}

.delete-btn:hover {
    cursor: pointer;
    color: red;
}

.form-management-button {
    background-color: #3c8dbc;
    border-color: #367fa9;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: white;
    padding: 12px 6px;
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 10px 10px;
    min-width: 50px;
}

    .form-management-button:hover,
    .form-management-button:focus {
        background-color: #367fa9;
        border-color: #204d74;
        text-decoration: none;
        outline: none;
    }

    .form-management-button:active {
        background-color: #204d74;
        border-color: #122b40;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

.table-container thead[data-test="datatable-head"] th {
    cursor: pointer;
}

.table-container tbody tr:hover {
    background-color: lightgrey;
}

.page-component-management-container .check-icon {
    color: royalblue;
}

.page-component-management-container .times-icon {
    color: red;
}

.page-component-management-container .table-container thead th:nth-child(8),
.page-component-management-container .table-container tbody td:nth-child(8),
.page-component-management-container .table-container thead th:nth-child(9),
.page-component-management-container .table-container tbody td:nth-child(9),
.page-component-management-container .table-container thead th:nth-child(10),
.page-component-management-container .table-container tbody td:nth-child(10) {
    text-align: center;
}

.page-component-management-container .table-opts-right {
    display: inline-block;
    float: right;
}

.page-component-management-container .checkbox-filter {
    box-sizing: border-box;
    width: 40px;
    height: 44px;
    color: rgba(46, 46, 46, .8);
    border: 1px solid rgb(225, 225, 225);
    outline: none;
}

.page-component-management-container .checkbox-title {
    vertical-align: top;
}
