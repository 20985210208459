.nav-container a,
.nav-container a:hover,
.nav-container a:focus,
.nav-container a:active {
    color: white;
    text-decoration: none;
}

.top-nav {
    width: 100%;
    background-color: #3c8dbc;
    color: white;
    z-index: 2;
    position: relative;
}

    .top-nav h1,
    .top-nav h3,
    .top-nav p {
        color: white;
        display: inline-block;
        margin: auto 2%;
        font-size: larger;
    }

    .top-nav h1:hover,
    .top-nav h3:hover,
    .top-nav p:hover {
        cursor: pointer;
    }

    .top-nav .logout {
        float: right;
        font-weight: normal;
    }

    .top-nav .nav-item {
        color: white;
        margin: auto 2%;
        font-size: larger;
        float: right;
        font-weight: normal;
        padding: 13px 0px;
    }

    .top-nav .settings-cog {
        -ms-transform: rotate(0deg); /* IE 9 */
        transform: rotate(0deg);
        transition: 0.5s;
    }

        .top-nav .settings-cog:hover {
            -ms-transform: rotate(20deg); /* IE 9 */
            transform: rotate(20deg);
            transition: 0.5s;
        }

.side-nav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #333;
    overflow-x: hidden;
    transition: 0.5s;
    /*padding-top: 60px;*/
}

    .side-nav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: large;
        color: white;
        display: block;
        transition: 0.3s;
        white-space: nowrap;
    }

        .side-nav a:hover {
            background-color: #666;
        }

    .side-nav .icon {
        padding-right: 10px;
        color: white;
        visibility: visible !important;
        display: inline-block !important;
        font-size: 28px;
    }

#main {
    transition: margin-left .5s;
    margin-left: 250px;
}

.ts-logo-wrapper {
    padding-left: 22px !important;
    height: 46.156px !important;
}

.ts-logo {
    width: 40px;
}

.top-nav .selected-parent-page,
.top-nav .selected-parent-page .icon,
.side-nav .selected-parent-page,
.side-nav .selected-parent-page .icon {
    color: yellow !important;
}

    .top-nav .selected-parent-page:hover,
    .top-nav .selected-parent-page .icon:hover,
    .side-nav .selected-parent-page:hover,
    .side-nav .selected-parent-page .icon:hover {
        color: yellow;
    }