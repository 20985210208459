@import url("https://use.typekit.net/fir3yvn.css");

body {
    font-family: sans-serif;
    font-family: century-gothic, sans-serif;
}
button {
    padding:20px;
    border:none;
    font-weight:600;
}
h1, h2, h3, h4, h5, h6 {
    padding: 15px 0px;
    color: #003219;
}

grid-half {
    display:grid;
    grid-template-columns:1fr 1fr;
}

.text-center {
    text-align:center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

/*column layouts*/

.grid-full {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-row-gap: 0;
}
.grid-half {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0;
}
.grid-thirds {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 0;
}

.grid-half img {
    justify-content: center;
    align-content: center;
    align-self: center;
    justify-self: center;
    width: 85%;
    grid-row-gap:0;
}
/*input widths*/
.full-width-input {
    display: block;
    margin-top: 20px;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    /*change all grid columns to 1 column*/
    .grid-full, .grid-half, .grid-thirds {
    grid-template-columns:1fr;
    }

}