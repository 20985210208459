.menu-items .dropdown {
    float: left;
    overflow: hidden;
    list-style: none;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    /*text-transform: uppercase;*/
    color: #003219;
    position: static;
}

    .menu-items .dropdown .dropdown-btn {
        font-weight: normal;
        border: none;
        outline: none;
        background-color: transparent;
        min-height: 30px;
        list-style: none;
        padding: 14px 7px;
        cursor: default;
        transition: ease-in-out 0.3s;
        /*text-transform: uppercase;*/
        color: #003219;
    }

        .menu-items .dropdown .dropdown-btn .right-caret-icon {
            -ms-transform: rotate(0deg); /* IE 9 */
            transform: rotate(0deg);
            transition: 0.5s;
            margin-left: 5px;
            font-size: 22px;
            color: #444;
        }

        .menu-items .dropdown:hover .right-caret-icon {
            -ms-transform: rotate(90deg); /* IE 9 */
            transform: rotate(90deg);
            transition: 0.3s;
        }

        .menu-items .navbar a:hover,
        .menu-items .dropdown:hover .dropdown-btn {
            background-color: transparent;
            transition: 0.4s;
        }

.menu-items .dropdown-content {
    display: none;
    position: absolute;
    background-color: #eaf9ff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    opacity: 1;
    transition: 0.4s;
}

    .menu-items .dropdown-content:hover {
        opacity: 1;
    }

    .menu-items .dropdown-content a {
        float: none;
        color: black;
        padding: 4px 12px;
        text-decoration: none;
        display: block;
        text-align: left;
        font-family: century-gothic, sans-serif;
        transition: ease-in-out 0.3s;
        font-size: 20px;
    }

        .menu-items .dropdown-content a:hover {
            background-color: #006762;
            transition: 0.4s;
            color: white;
        }

.menu-items .dropdown:hover .dropdown-content,
.menu-items dropdown:active,
.menu-items dropdown:focus,
.menu-items dropdown:hover {
    display: block;
    transition: 0.4s;
}

.menu-items .direct-link:hover,
.menu-items .direct-link:focus {
    text-decoration: none;
    color: white;
}

.menu-items .dropdown .direct-dropdown-link:hover,
.menu-items .dropdown .direct-dropdown-link:focus {
    text-decoration: none;
    color: #006762;
    background-color: transparent;
}

.menu-items .dropdown .direct-dropdown-link a:hover,
.menu-items .dropdown .direct-dropdown-link a:focus {
    text-decoration: none;
    color: #006762;
}
