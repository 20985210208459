@import url("https://use.typekit.net/lzq6ner.css");

body {
    font-family: sans-serif !important;
}

ul {
    display: block;
}

.full-width-wrapper {
    width: 125%;
    margin-left: -12.5%;
}

/*font settings*/
.lightweight {
    font-weight: 300;
}

.midweight {
    font-weight: 400;
}

.heavyweight {
    font-weight: 700;
}

/*p {
    font-size: 1.8rem;
}*/

.smallfont {
    font-size: 16px;
}

/*navigation area*/
.main-nav-wrap {
    width: 100%;
    display: inline-block;
    padding-bottom: 5%;
    background-color: #eaf9ff;
    color: white;
}

.main-nav-wrap .menu-items .dropdown-btn {
    padding-top: 14px;
}

    .main-nav-wrap .social-contact .header-contact-info {
        vertical-align: super;
        padding-top: 5px;
    }

    .main-nav-wrap .social-contact .header-contact-info a,
    .main-nav-wrap .social-contact .header-contact-info p,
    .main-nav-wrap .social-contact .header-contact-info i {
        color: white;
        font-size: 22px;
    }

.main-nav-wrap__super .sub-nav-wrap {
    grid-column: 1 /span 2;
    height: 10px;
    background-color: #e37051;
}

.main-nav-wrap .nav-extras {
    text-align: right;
    padding-top: 3px;
}

    .main-nav-wrap .nav-extras .nav-extras-main {
        display: inline-flex;
    }

        .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container {
            display: inline-block;
            padding-right: 10px;
        }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr td,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr td div,
            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr td div table {
                height: 100% !important;
            }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #gsc-i-id1 {
                background-image: url("../images/ClientStatic/SearchPlaceholder.PNG") !important;
            }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsib_a {
                padding: 18px 2px;
                width: 500px;
            }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-input {
                padding-right: 0;
            }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-input-box {
                width: 80%;
                margin-left: auto;
            }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-search-button.gsc-search-button-v2 {
                background-color: #819dcf;
                color: white;
                font-size: 20px;
                border-color: transparent;
                height: 100%;
                padding: 6px 12px;
            }

                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-search-button.gsc-search-button-v2 svg {
                    block-size: 50px;
                    inline-size: 50px;
                }

        .main-nav-wrap .nav-extras .nav-extras-main input {
            font-size: 20px;
            padding: 18px;
            width: 38%;
        }

        .main-nav-wrap .nav-extras .nav-extras-main .search-button{
            background-color: #819dcf;
            color: white;
            font-size: 20px;
            width: 6%;
        }

        .main-nav-wrap__super .nav-extras .nav-extras-main .pay-bill-button {
            background-color: #006f66;
            color: white;
            text-transform: capitalize;
            font-size: 28px;
            font-weight: bold;
            text-decoration: none;
            padding: 15px 25px 13px 25px;
            margin-left: 3px;
            margin-right: 10px;
            text-align: center;
        }

    .main-nav-wrap .nav-extras .nav-extras-sub {
        margin-top: 10px;
    }

        .main-nav-wrap .nav-extras .nav-extras-sub span {
            /*margin-right: 10px;*/
        }

        .main-nav-wrap .nav-extras .nav-extras-sub .phone {
            background-color: #7298bc;
            padding: 8px;
            padding-bottom: 6px;
            border-radius: 5px;
            margin-right: 10px;
        }

            .main-nav-wrap .nav-extras .nav-extras-sub .phone i,
            .main-nav-wrap .nav-extras .nav-extras-sub .phone p {
                color: white;
                font-size: 22px;
            }

                .main-nav-wrap .nav-extras .nav-extras-sub .phone i {
                    margin-right: 10px;
                }

            .main-nav-wrap .nav-extras .nav-extras-sub .phone a {
                text-decoration: none;
            }

        .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container {
            display: inline-block;
            margin-right: 10px;
        }

            .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container .goog-te-gadget-simple {
                border-radius: 5px;
                font-size: 20px;
                padding: 0px;
                padding-top: 1px;
            }

            .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container .goog-te-gadget-simple a:hover {
                text-decoration: none;
            }

.main-nav ul {
    display: inline-flex;
}

.main-nav li {
    list-style: none;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    text-transform: none;
    color: #77787c;
    padding: 10px 12px;
}

    .main-nav li.active {
        font-weight: 700;
        color: #f9a73e;
    }

    .main-nav li:hover {
        background-color: white;
        color: #f9a73e;
    }

.main-nav {
    text-align: right;
}

.nav-items {
    margin-right: 10%;
}

.main-logo-wrapper {
    justify-content: unset;
    /*margin-left: 10%;*/
}

/*after header*/

.after-header {
    margin: 100px 0;
}

    .after-header h1, .after-header h1, .after-header h3, .after-header h4, .after-header h5, .after-header h6 {
        padding: 5px;
        margin: 0;
    }

.after-header-hr {
    background-color: #f9a73e;
    padding: 1px;
    border: 0;
    width: 45%;
    margin: 50px auto 100px auto;
}

/*columns feature area*/
.feature-area-padding {
    padding: 0 0 50px 0;
    margin: auto 20%;
}

.feature-element ul li {
    color: #77787c;
    text-transform: none;
    display: inline-flex;
}

    .feature-element ul li:last-of-type {
        margin-bottom: 25px;
    }

    .feature-element ul li:hover {
        background-color: white;
        cursor: default;
    }

    .feature-element ul li::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        font-size: 20px;
        color: #adbd37;
        margin: -2px 5px 0 0;
    }

.html-editor-wrap li {
    list-style: none !important;
}

.html-editor-wrap ul li::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    font-size: 20px;
    color: #adbd37;
    margin: -2px 5px 0 0;
}

/*.calendar-wrapper {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #a0d0f7;
}*/
.calendar-wrapper {
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
}

.lightweight .text-right .tsdark {
    color: grey;
    font-size: 48px;
}

.grid-thirds.feature-thirds {
    grid-gap: 70px;
}

    .grid-thirds.feature-thirds .feature-element {
        -webkit-box-shadow: -2px 2px 15px 0px rgba(0,0,0,.4);
        box-shadow: -2px 2px 15px 0px rgba(0,0,0,.4);
    }

.feature-hr {
    background-color: #f9a73e;
    padding: 1px;
    border: 0;
    width: 20%;
    margin: 0 auto 15px auto;
}
/*buttons*/
.greenbutton {
    background: #adbd37;
    color: white;
    padding: 15px;
    border-radius: 5px;
    transition: 0.5s;
}

.greenbutton {
    background: #adbd37;
    color: white;
    padding: 15px;
    border-radius: 5px;
    width: 150px;
}

.center-button {
    margin: 0 auto;
}

.call::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f095";
    font-size: 20px;
    margin: -5px 10px 4px -6px;
}

.submit-button {
    color: white;
    background: #adbd37;
    border: none;
    padding: 10px 20px;
    float: right;
}

.call {
    margin-bottom: 50px;
}

.cardContainerFourWide {
    width: 95%;
    margin: 20px auto;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    /*background-color:rgb(245,256,260);*/
    height: 800px;
    grid-gap: 20px;
}

.card {
    background-color: white;
    -webkit-box-shadow: 2px 14px 18px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 2px 14px 18px 0px rgba(0,0,0,0.12);
    box-shadow: 2px 14px 18px 0px rgba(0,0,0,0.12);
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    overflow: auto;
    overflow-y: hidden;
}

.cardTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
}

.leftCardTitle {
    float: left;
    padding-left: 10px;
    text-align: left;
    width: 75%
}

.viewAll {
    float: right;
    width: 25%;
    font-weight: bold;
    padding-right: 5px;
    font-size: .75em;
    padding-top: .4em;
}

.itdContainer {
    height: 100%;
}

.warning-card {
    background-color: #ed383f;
    color: white;
    height: 100%;
    text-align: left;
}

.update-card {
    height: 100%;
    text-align: left;
    background-color: #a8b4db;
    color: white;
}

.warning-card p,
.update-card p {
    margin: 0;
}

.flat-card {
    background-color: transparent;
    box-shadow: none;
    border: transparent;
}

.itdImage {
    float: left;
    padding: 2.5%;
}

.itdTitle {
    text-align: left;
    font-size: .75em;
}

.itdTeaser {
    text-align: left;
}

/*feature 5050*/
.featured-wrapper {
    padding: 30px;
}

.feature-5050-contain {
    background: #f9a73e;
    min-height: 400px;
    color: white;
}

.feature-5050 {
    grid-gap: 50px;
}

    .feature-5050 h1 {
        color: white;
    }

    .feature-5050 hr {
        border: 0;
        background-color: white;
        padding: 1px;
        width: 70%;
        text-align: left;
        margin: 20px 0 0 0;
    }

    .feature-5050 div {
        padding: 10%;
        align-self: center;
        text-align: center;
    }
/*override core image size*/
.features2 img, .getstarted2 img {
    width: 95%;
}

.features2 hr, .getstarted2 hr {
    border: none;
    padding: 1px;
    background: #f9a73e;
}

.features2 {
    max-height: 550px;
}

    .features2 .image-full-bg {
        background-position: center;
        background-size: cover;
        height: auto;
        width: 100%;
        text-align: center;
    }

.getstarted2 .image-full-bg {
    background-position: center;
    background-size: cover;
    height: auto;
    width: 100%;
}

.grid5050 .grid5050-image {
    width: auto;
}

.flex-single {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 30px;
}

    .flex-single p::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        font-size: 40px;
        margin: -15px 20px 4px -6px;
        color: #adbd37;
    }

/*inputs*/
input[type='text'], input[type='password'], input[type='email'], input[type='tel'], textarea {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    height: 50px;
    color: rgba(46, 46, 46, .8);
    border: 1px solid rgb(225, 225, 225);
    outline: none;
    margin: 0 0 0 0;
    padding-left: 15px;
    padding-right: 15px;
    resize: none;
}

textarea {
    min-height: 200px;
}

.contact-inputs {
    grid-gap: 10px;
}

.contact-input-wrapper {
    padding: 30px;
    color: grey;
}

    .contact-input-wrapper h1 {
        color: grey;
    }

.comment-wrap {
    margin-top: 10px;
}

/*footer*/

/*footer {
    display: block;
    position: relative;
    clear: both;
    background: grey;
    color: white;
}

    footer p {
        font-size: 1rem;
    }

    footer ul {
        text-align: center;
    }

        footer ul li {
            list-style: none;
            cursor: pointer;
            transition: ease-in-out 0.3s;
            text-transform: none;
            padding: 10px 12px;
            display: inline-block;
        }

            footer ul li:hover {
                background: grey;
            }

    footer .grid-thirds {
        align-items: center;
        justify-content: center;
    }*/

.footer {
    padding: 0 10%;
}

.footer-logo img {
    max-width: 130px;
    padding: 15px;
}

.social-contact {
    display: none;
}

.footer-wrap {
    background: grey;
    /*color: white;*/
}

    .footer-wrap .social-icons {
        /*color: white;*/
        text-align: center;
        padding: 0 !important;
    }

    .footer-wrap .copyright-info p,
    .footer-wrap .powered-by p {
        font-size: 15px;
    }

    .footer-wrap .footer ul {
        display: inline-flex;
    }

/*********************************************************************************************************************************/

.announcement-wrapper {
    background-color: #e16541;
}

.home-announce {
    text-align: center;
    color: white;
    background: #5397d2;
    font-size: 18px;
    display: grid;
    grid-template-columns: 1.5fr 12fr 1fr;
    padding: 0 10%;
    padding-left: 0;
    margin-left: 10%;
}

    .home-announce p {
        padding: 5px;
        margin: 0;
    }

    .home-announce .announcement-title {
        background-color: #095587;
        font-size: 34px;
        font-weight: bold;
        background-image: -webkit-linear-gradient( 150deg, #5397d2 35%, #e16541 35%);
        text-align: left;
        padding-left: 10px;
    }

    .home-announce .announcement-content {
        padding: 5px 0%;
        text-align: left;
    }

    .home-announce .announcement-remove {
        color: #e16541;
        font-size: 30px;
        cursor: pointer;
    }

        .home-announce .announcement-remove svg {
            background-color: white;
            border-radius: 50%;
            border: #e16541 2px solid;
        }

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 0;
    top: 0;
    left: 0;
}

/*.breadcrumb-wrap {
    text-align: left;
}*/

.breadcrumb-wrap {
    text-align: left;
}

    .breadcrumb-wrap .breadcrumb a,
    .breadcrumb-wrap .breadcrumb span {
        padding: 0 3px 0 3px;
    }

        .breadcrumb-wrap .breadcrumb a::after,
        .breadcrumb-wrap .breadcrumb span::after {
            content: "/";
            padding: 0 0 0 4px;
        }

    .breadcrumb-wrap .breadcrumb {
        background-color: transparent !important;
        margin-bottom: 0;
        padding: 0;
        font-size: 10px;
    }

.cart-wrap {
    text-align: left;
    margin: 10px auto;
    width: 70%;
}

.cart {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: start;
    justify-items: start;
    grid-gap: 20px;
}

.cart-wrap hr {
    border-top: 1px solid #fafafa;
}

.cart-subtotal {
    display: block;
    width: 100%;
    position: relative;
    float: left;
}

    .cart-subtotal h3 {
        display: block;
        position: relative;
        float: left;
        padding: 5px;
    }

        .cart-subtotal h3:nth-child(2) {
            float: right;
        }

.checkout-button {
    padding: 10px 0 10px 0;
    width: 100%;
    background-color: #adaf44;
    display: block;
    color: white;
    font-weight: 700;
    text-align: center;
}

.checkout-button-wrap a::before {
    font-family: "Font Awesome 5 Free";
    content: "\f023";
    color: #ffffff;
    padding-right: 10px;
}

.checkout-button-wrap a {
    transition: ease-in-out 0.5s;
}

    .checkout-button-wrap a:hover {
        opacity: 0.8;
    }

.checkout-button-wrap {
    width: 100%;
    display: block;
    position: relative;
    float: left;
}

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.item-cart-image a img {
    max-width: 230px;
    max-height: 300px;
}

.remove-btn:hover {
    cursor: pointer;
}

.footer-wrap {
    width: 100%;
    min-height: auto;
    display: block;
    justify-content: center;
    align-content: center;
    background: #6697c2;
    color: white;
}

    .footer-wrap li {
        font-size: 22px;
    }

        .footer-wrap li:hover {
            color: #0056b3 !important;
            background-color: transparent;
        }

        .footer-wrap .footer-column-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 5px;
            margin: 0 auto;
            width: 100%;
        }

        .footer-wrap .footer-column-grid div {
            padding: 3% 0 3% 15%;
        }

            .footer-wrap .footer-column-grid div p {
                margin-bottom: 0px;
            }

        .footer-wrap .footer-info-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 5px;
            margin: 0 auto;
            width: 100%;
        }

        .footer-wrap .footer-social-column {
            text-align: left;
        }

            .footer-wrap .footer-social-column span {
                font-weight: bold;
                font-size: 16pt;
            }

        .footer-wrap .legal-info {

        }

            .footer-wrap .legal-info span {
                padding: 5px;
            }

    .footer-wrap .super-footer-container {
        background-color: #eaf9ff;
        color: #095587;
        display: flex;
        padding: 0 10%;
    }

        .footer-wrap .super-footer-container .super-footer-header-wrapper {
            width: 40%;
        }

        .footer-wrap .super-footer-container .super-footer-header {
            margin: 25px auto;
            text-align: center;
            border-right: 4px solid #e37051;
        }

            .footer-wrap .super-footer-container .super-footer-header img {
                width: 140px;
            }

            .footer-wrap .super-footer-container .super-footer-header .header-text-container {
                padding-left: 5%;
                display: inline-block;
                vertical-align: middle;
            }

                .footer-wrap .super-footer-container .super-footer-header .header-text-container .super-text {
                    display: block;
                    font-size: 34px;
                    font-weight: bold;
                }

                .footer-wrap .super-footer-container .super-footer-header .header-text-container .sub-text {
                    font-size: 24px;
                    font-weight: bold;
                    float: left;
                    margin-top: -10px;
                }

        .footer-wrap .super-footer-container .super-footer-content {
            margin: 50px auto;
            display: grid;
            grid-template-columns: 2fr 3fr;
        }

            .footer-wrap .super-footer-container .super-footer-content .content-description-container {
                padding: 10px 40px;
                font-size: 18px;
            }

                .footer-wrap .super-footer-container .super-footer-content .content-description-container .content-description {
                    display: block;
                }

            .footer-wrap .super-footer-container .super-footer-content form {
                padding: 14px 0;
            }

                .footer-wrap .super-footer-container .super-footer-content form input {
                    padding: 12px;
                    width: 60%;
                }

                .footer-wrap .super-footer-container .super-footer-content form button {
                    padding: 14px;
                    background-color: #095587;
                    color: white;
                }

                .footer-wrap .super-footer-container .super-footer-content form .submit-btn-wrapper {
                    display: inline-block;
                    width: 30%;
                }

                    .footer-wrap .super-footer-container .super-footer-content form .submit-btn-wrapper input {
                        background-color: #095587;
                        color: white;
                    }

.footer {
    width: 100%;
    text-align: center;
    border-top: 10px solid #e37051;
}

ul {
    /*display: inline-flex;*/
}

li {
    /*list-style: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;*/
    /*text-transform: uppercase;*/
}

    li:hover {
        /*background-color: #a5a5a5;*/
    }

.dataTables_paginate li {
    padding: 0;
}

.company-name {
    width: 100%;
    text-align: center;
    margin: 0 0 20px 0;
}

.logo {
    width: 400px;
    min-height: 200px;
    margin: 0 auto;
    position: relative;
    display: block;
    /*background: url(../images/OBB.svg);*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.social-icons {
    text-align: inherit;
    width: 100%;
    color: white;
    /*padding: 10px;*/
    box-sizing: border-box;
}

    .social-icons a i {
        font-size: 35px;
        margin: 0px 5px;
    }

.copyright-info {
    /*text-align: left;
    margin-left: 8%;*/
    text-align: center;
}

    .copyright-info p {
        text-align: center;
        padding: 0;
        margin: 0;
    }

/*.powered-by {
    text-align: left;
    margin-left: 8%;
}*/

    .powered-by p {
        text-align: center;
        padding: 0;
        margin: 0;
    }

.card .homeCTA {
    height: inherit;
}

.homeCTA {
    width: 100%;
    max-width: 100%;
}

    .homeCTA .wrap img {
        max-width: 100%;
    }

    .homeCTA button {
        color: white;
        background-color: rgb(237, 56, 63);
        border-radius: 30px;
        padding: 15px;
        font-family: open-sans, sans-serif;
        width: 100%;
        margin: 0 auto;
        text-transform: uppercase;
        transition: ease-in-out 0.3s;
        cursor: pointer;
    }

        .homeCTA button:hover {
            background-color: #e9161d;
        }

    .homeCTA .react-slideshow-container .default-nav {
        padding: 0;
    }

    .homeCTA .alice-carousel__stage {
        width: auto;
    }

.action-area {
    padding: 20px;
    color: white;
    text-align: center;
    justify-self: center;
    align-self: end;
    width: 20%;
}

.homeCTA .action-area {
    margin: auto;
    top: -330px;
    position: relative;
    font-size: 26px;
    width: auto;
}

.homeCTA .sub-action-text {
    margin: auto;
    top: -346px;
    position: relative;
    font-size: 22px;
    width: auto;
    color: white;
}

.homeCTA .button-area {
    margin: auto;
    position: relative;
    width: fit-content;
}

    .homeCTA .button-area.button-upper-left {
        top: -535px;
        left: -40%;
    }

    .homeCTA .button-area.button-upper-right {
        top: -535px;
        left: 40%;
    }

    .homeCTA .button-area.button-bottom-left {
        top: -125px;
        left: -40%;
    }

    .homeCTA .button-area.button-bottom-right {
        top: -125px;
        /*top: -25px;*/
        left: 40%;
    }

    .homeCTA .button-area.button-bottom-middle {
        top: -125px;
    }

    .homeCTA .button-area.button-middle {
        top: -333px;
    }

    .homeCTA .button-area.in-card.button-upper-left {
        top: -420px;
        left: -40%;
    }

    .homeCTA .button-area.in-card.button-upper-right {
        top: -420px;
        left: 40%;
    }

    .homeCTA .button-area.in-card.button-bottom-left {
        top: -70px;
        left: -40%;
    }

    .homeCTA .button-area.in-card.button-bottom-right {
        top: -70px;
        left: 40%;
    }

    .homeCTA .button-area.in-card.button-bottom-middle {
        top: -70px;
    }

    .homeCTA .button-area.in-card.button-middle {
        top: -333px;
    }

.banner-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 500px;
    /*display: grid;*/
}

.card .homeCTA .banner-bg {
    height: 100%;
    min-height: unset;
}

.homeCTA .banner-bg img {
    margin: 0 auto;
    width: 100%;
    max-height: 600px;
}

.card .homeCTA .banner-bg img {
    max-height: unset;
    height: 100%;
}




/*.banner-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

    .banner-container .banner-bg {
        grid-row: span 3/-1;
        grid-column: span 3/-1;
    }

    .banner-container .button-area {
        margin: auto;
        width: fit-content;
    }

        .banner-container .button-area.button-upper-left {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 1;
            grid-column-end: 1;
        }

        .banner-container .button-area.button-upper-right {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 3;
            grid-column-end: 3;
        }

        .banner-container .button-area.button-bottom-left {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 1;
        }

        .banner-container .button-area.button-bottom-right {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 3;
        }

        .banner-container .button-area.button-bottom-middle {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: 2;
        }

        .banner-container .button-area.button-middle {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 2;
        }*/


/**********************************Document Table*****************************************************/

.document-table-container {
    
}

    .document-table-container .table-name {
        text-align: center;
        font-size: x-large;
        font-weight: bold;
    }

    .document-table-container .table-container table {
        margin-top: 10px;
    }

    .document-table-container .table-container .document-type-filter {
        padding: 14px 12px;
        min-width: 25%;
        border-radius: 0px;
        position: absolute;
    }

    .document-table-container .table-container .custom-search-bar-wrapper {
        display: flow-root;
    }

        .document-table-container .table-container .custom-search-bar-wrapper .custom-search-bar {
            float: right;
            width: 20%;
        }

    .document-table-container .compact-table {
        text-align: left;
    }

        .document-table-container .compact-table .compact-row {
            margin-bottom: 1rem;
        }

            .document-table-container .compact-table .compact-row img {
                padding-right: 5px;
            }

/**********************************Event Table*****************************************************/

.event-table-container {
    margin-bottom: 2%;
}

    .event-table-container .table-name {
        text-align: center;
        font-size: x-large;
        font-weight: bold;
    }

    .event-table-container .table-container {

    }

    .event-table-container .table-container table {
        margin-top: 10px;
    }

    .event-table-container .full-table tbody tr td:nth-child(1) {
        padding: 0;
        vertical-align: middle;
    }

    .event-table-container .compact-table td {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .event-table-container .compact-table .compact-details-data {
        padding: 0;
        vertical-align: top;
    }

    .event-table-container .compact-table .compact-details {
        text-align: left;
        padding-left: 5px;
        font-size: 12px;
    }

        .event-table-container .compact-table .compact-details .compact-details--time {
            color: #9fcff7;
            text-transform: capitalize;
        }

    .event-table-container .compact-table .compact-table-row:hover {
        background-color: lightgrey;
        cursor: pointer;
    }

    .event-table-container tbody tr:hover {
        cursor: pointer;
    }

    .event-table-container .event-calendar-container {
        margin-bottom: 2%;
    }

        .event-table-container .event-calendar-container .rbc-toolbar-label {
            font-size: 26px;
            text-align: right;
        }

        .event-table-container .event-calendar-container .rbc-month-row {
            /*overflow-y: scroll;*/
        }

        .event-table-container .event-calendar-container .rbc-row-content .rbc-row:nth-of-type(4) .rbc-row-segment .rbc-event {
            background-color: transparent;
            color: black;
            text-align: center;
        }

        .event-table-container .event-calendar-container .rbc-row-content .rbc-row:nth-of-type(4) .rbc-row-segment .rbc-event:hover {
            text-decoration: underline;
        }

    .event-table-container #eventTable .calendar-event-filter {
        position: relative;
        top: 45px;
        font-size: 22px;
        background-color: #095587;
        color: white;
        padding: 5px;
        border-radius: 6px;
        z-index: 3;
    }

        .event-table-container #eventTable .calendar-event-filter .calendar-event-filter-removal {
            padding-left: 1%;
            cursor: pointer;
        }

    .event-table-container .event-table {
        border-top: 1px solid lightgrey;
        padding-top: 1%;
    }

        .event-table-container .event-table.calendar-filter {
            padding-top: 0;
        }

    .event-table-container .checkbox-wrapper {
        font-size: 22px;
    }

        .event-table-container .checkbox-wrapper .checkbox-title {
            position: relative;
            top: -8px;
        }

        .event-table-container .checkbox-wrapper .checkbox-filter {
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            color: rgba(46, 46, 46, .8);
            border: 1px solid rgb(225, 225, 225);
            outline: none;
        }

/********************************Event Page*********************************************/

.event-container {
    /*width: 90%;
    margin: auto;
    text-align: center;*/
}

    .event-container .event-title,
    .event-container .event-time {
        text-align: center;
        margin-bottom: 5px;
    }

        .event-container .event-title {
            font-size: x-large;
        }

    .event-container .event-time {
        font-size: x-large;
        color: #095587;
    }

        .event-container .event-image {
            width: 100%;
            height: 200px;
        }

    .event-container .event-description {
        padding: 10px;
        text-align: left;
    }

/*************************Blog Table Container*******************************/

.blog-table-container__full {
    margin: auto;
    width: 100%;
}

.blog-table-container .table-name {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
}

.blog-table-container .info-icon {
    font-size: 20px !important;
    vertical-align: text-bottom;
}

    .blog-table-container .info-icon:hover {
        color: #80ba54 !important;
    }

.blog-table-container .filter-options,
.blog-table-container .current-filters {
    background-color: #eaf9ff;
    color: black;
    padding: 10px;
    height: fit-content;
    min-height: 280px;
    margin-bottom: 10%;
}

    .blog-table-container .filter-options .filter-opt-box-title,
    .blog-table-container .current-filters .filter-opt-box-title {
        font-size: 26px;
        margin-bottom: 5px;
        color: black;
        font-weight: bold;
    }

    .blog-table-container .filter-options .blog-tag,
    .blog-table-container .current-filters .blog-tag {
        display: block;
        font-size: 20px;
    }

        .blog-table-container .filter-options .blog-tag:hover,
        .blog-table-container .current-filters .blog-tag:hover {
            cursor: pointer;
            color: #0056b3;
        }

.blog-table-container .table-container table {
    margin-top: 10px;
}

.blog-table-container .full-table {
    width: 85%;
    margin: auto;
}

    .blog-table-container .full-table a {
        text-decoration: none;
    }

    .blog-table-container .blog-tag:hover {
        cursor: pointer;
        color: #0056b3;
    }

    .blog-table-container .full-table .full-table-row {
        border-top: 1px solid;
        text-align: center;
        padding-bottom: 10px;
    }

        .blog-table-container .full-table .full-table-row.first-full-table-row {
            border-top: none;
        }

    .blog-table-container .full-table .blog-data {
        margin: 20px auto;
        width: 65%;
        display: grid;
        grid-template-columns: 1fr 4fr;
    }

        .blog-table-container .full-table .blog-data .blog-teaser {
            vertical-align: top;
            text-align: left;
            padding-left: 15px;
            padding-top: 5px;
        }

            .blog-table-container .full-table .blog-data .blog-teaser p {
                margin-bottom: 10px;
            }

        .blog-table-container .full-table .blog-data img {
            width: 200px;
            height: 110px;
        }

        .blog-table-container .full-table .blog-tags p {
            margin-bottom: 3px;
        }

.blog-table-container.blog-table-container__full .blog-filter-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-bottom: 5px;
    grid-gap: 0px 100px;
}

    .blog-table-container.blog-table-container__full .blog-filter-grid .blog-filter-grid__2 {
        grid-column: 2 /span 1;
    }

.blog-table-container .compact-table td {
    padding-top: 5px;
    padding-bottom: 5px;
}

.blog-table-container .compact-table .compact-details-data {
    padding: 0;
    vertical-align: top;
    padding-top: 5px;
}

.blog-table-container .compact-table .compact-details {
    text-align: left;
    padding-left: 15px;
    font-size: 14px;
}

    .blog-table-container .compact-table .compact-details .compact-details--title {
        color: #9fcff7;
        text-transform: capitalize;
    }

.blog-table-container .compact-table .compact-table-row:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.blog-table-container .featured-blog-container {
    margin-bottom: 3%;
}

    .blog-table-container .featured-blog-container .featured-image img {
        max-width: 100%;
    }

    .blog-table-container .featured-blog-container .featured-blog-title {
        
    }

        .blog-table-container .featured-blog-container .featured-blog-title h3 {
            padding: 0;
            color: #095587;
            font-size: 40px;
        }

    .blog-table-container .featured-blog-container .featured-timestamp {
        padding-bottom: 10px;
        color: #b4b1b2;
    }

        .blog-table-container .featured-blog-container .featured-timestamp .blog-tag:hover {
            cursor: pointer;
            text-decoration: underline;
            color: #095587;
        }

    .blog-table-container .featured-blog-container .featured-blog-teaser p {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;
    }

    .blog-table-container .featured-blog-container .featured-blog-teaser .continue-tag {
        color: #095587;
        font-size: 18px;
    }

.blog-table-container .blog-table-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 5px;
    grid-gap: 50px 25px;
}

    .blog-table-container .blog-table-grid .blog-card-container {
        background-color: #eaf9ff;
    }

        .blog-table-container .blog-table-grid .blog-card-container .blog-image img {
            /*max-width: 100%;*/
            height: 190px;
            width: 100%;
        }

        .blog-table-container .blog-table-grid .blog-card-container .blog-data-container {
            padding: 4% 5%;
        }

        .blog-table-container .blog-table-grid .blog-card-container .blog-title h3 {
            padding: 0;
            margin: 0;
            color: #095587;
            font-size: 22px;
        }

        .blog-table-container .blog-table-grid .blog-card-container .blog-upload {
            color:#b4b1b2;
        }

.blog-table-container .blog-filter-container .filter-archive-box {
    font-size: 20px;
}

    .blog-table-container .blog-filter-container .filter-archive-box .archive-box-title {
        color: #095587;
        padding-bottom: 10px;
    }

    .blog-table-container .blog-filter-container .filter-archive-box .archive-tag {
        display: block;
    }

    .blog-table-container .blog-filter-container .filter-archive-box .show-archives-btn,
    .blog-table-container .blog-filter-container .filter-archive-box .hide-archives-btn {
        color: #095587;
        cursor: pointer;
        padding-top: 5px;
    }

        .blog-table-container .blog-filter-container .filter-archive-box .show-archives-btn:hover,
        .blog-table-container .blog-filter-container .filter-archive-box .hide-archives-btn:hover {
            text-decoration: underline;
        }

.blog-table-container .paginated-button-row {
    margin: 5% 2%;
}

    .blog-table-container .paginated-button-row span {
        background-color: #095587;
        color: white;
        padding: 8px 12px;
        border-radius: 4px;
        margin-right: 5px;
        cursor: pointer;
    }

        .blog-table-container .paginated-button-row span.current-page {
            background-color: #0274bd;
        }

.blog-table-container .popular-post-box {
    margin-top: 7%;
}

    .blog-table-container .popular-post-box .popular-post-header {
        color: #095587;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .blog-table-container .popular-post-box .popular-post-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin-bottom: 20px;
        grid-gap: 20px;
    }

        .blog-table-container .popular-post-box .popular-post-container .popular-post-image {
        }

            .blog-table-container .popular-post-box .popular-post-container .popular-post-image img {
                max-width: 100%;
                min-height: 100%;
            }

        .blog-table-container .popular-post-box .popular-post-container .popular-post-content {
        }

        .blog-table-container .popular-post-box .popular-post-container .popular-post-title {
            color: #095587;
            font-size: 20px;
        }

            .blog-table-container .popular-post-box .popular-post-container .popular-post-title h3 {
                color: #095587;
                font-size: 20px;
                margin: 0;
                padding: 0;
            }

        .blog-table-container .popular-post-box .popular-post-container .popular-post-tags {
            color: #b4b1b2;
            font-size: 14px;
        }

/********************Blog Container************************************/
.blog-container {
    /*width: 75%;
    margin: auto;*/
}

    .blog-container ul {
        padding-left: 3%;
    }

    .blog-container .blog-title,
    .blog-container .blog-upload {
        text-align: center;
        margin-bottom: 5px;
    }

    .blog-container .blog-title {
        font-size: 32px;
    }

    .blog-container .blog-upload {
        font-size: x-large;
        color: #095587;
    }

    .blog-container .blog-image {
        width: 100%;
        height: 200px;
    }

    .blog-container .blog-content {
        padding: 10px;
        padding-bottom: 20px;
        border-bottom: solid black 1px;
    }

    .blog-container .blog-tags {
        padding: 10px;
        text-align: center;
    }

    .blog-container .other-blog-preview-container {
        border-top: 1px solid black;
    }

        .blog-container .other-blog-preview-container .other-blog-preview-title {
            margin: 1% 0;
            font-size: 32px;
            font-weight: bold;
            color: #173668;
        }

        .blog-container .other-blog-preview-container .other-blog-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
            width: 100%;
            margin: 1% auto 2% auto;
        }

            .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet {
                height: 300px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 450px;
                cursor: pointer;
                text-decoration: none;
                width: 100%;
            }

                .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet .snippet-text {
                    margin: 10px;
                    background-color: white;
                    padding: 10px;
                    border-left: 10px solid #e16541;
                    text-decoration: none;
                    position: relative;
                    top: 210px;
                }

                    .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet .snippet-text .super-text {
                        color: #5a5a5a;
                        font-weight: bold;
                    }

                    .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet .snippet-text .sub-text {
                        color: #38517c;
                    }

        .blog-container .other-blog-preview-container .other-blog-preview-see-all {
            margin: 1% 0 5% 0;
        }

            .blog-container .other-blog-preview-container .other-blog-preview-see-all a {
                font-size: 24px;
                color: white;
                background-color: #e16541;
                text-decoration: none;
                padding: 5px 50px;
                border-radius: 6px;
            }

/****************************News Table************************************************************/

.news-table-container img {
    width: 130px;
    height: 85px;
}

.news-table-container .table-name {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

    .news-table-container .full-table tbody tr td:nth-child(1) {
        padding: 0;
        vertical-align: middle;
    }

.birthday-table-container {
    margin-left: 5%;
    margin-right: 5%;
}

    .birthday-table-container .table-name {
        text-align: center;
        font-size: x-large;
        font-weight: bold;
    }

    .birthday-table-container .full-table {
        margin-top: 10px;
    }

    .birthday-table-container .compact-table .row--compact {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 10px;
        text-align: left;
        padding-bottom: 5px;
        font-size: 14px;
    }

    .birthday-table-container .compact-table .bday-name,
    .birthday-table-container .compact-table .bday-birthday {
        font-weight: bold;
    }

.html-editor-wrap {
    width: 98%;
    margin: 0 auto;
    padding-top: 1%;
    overflow-wrap: break-word;
}

    .html-editor-wrap ul {
        display: block;
        padding-left: 2%;
    }

    .html-editor-wrap li {
        list-style: disc;
        cursor: auto;
        text-transform: none;
        padding: 1px 0px;
    }

        .html-editor-wrap li:hover {
            background-color: transparent;
        }

.main-nav-wrap {
    display: grid;
    grid-template-columns: 2fr 5fr;
    padding: 0;
    padding-bottom: 5px;
    background-image: url("../images/ClientStatic/RiverwalkAerialShot_Opacity3.PNG");
    height: 225px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

    .main-nav-wrap .header-contact-info {
        display: inline-flex;
        position: relative;
        text-align: center;
        top: auto;
        right: auto;
    }

.main-nav-wrap__super .main-nav-wrap,
.main-nav-wrap__super .sub-nav-wrap,
.main-nav-wrap__super .breadcrumb-wrap,
.main-nav-wrap__super #googleSearchResultsContainer {
    padding: 0 10%;
}

.main-nav-wrap__super .mobile-sub-nav-container {
    display: none;
}

.main-nav-wrap__super #googleSearchResultsContainer .gsc-control-cse {
    padding: 0;
}

.social-contact {
    display: inline-block;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 22px;
    text-align: center;
}

.header-contact-info i, .header-contact-info p {
    margin: 0 0 0 0;
}

.social-contact .social-icons {
    margin-left: 10px;
}

.nav-items {
    justify-self: center;
    align-self: center;
    flex: 1;
    margin-right: auto;
}

.main-logo-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    align-self: center;
    justify-self: center;
    justify-content: unset;
}

.main-nav {
    width: 100%;
    text-align: right;
    display: flex;
    padding-right: 10px;
    font-weight: bold !important;
    margin-top: 5px;
}

.sub-nav {
    padding-right: 10px;
    font-weight: bold !important;
}

.mobile-nav-button, .mobile-nav {
    display: none;
}

main-nav ul {
    display: inline-flex;
}

.main-nav li {
    list-style: none;
    padding: 10px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    color: black;
    font-size: 22px;
}

.menu-items .direct-link li {
    color: black;
    font-size: 24px;
    padding: 10px;
}

    .menu-items .direct-link li:hover {
        background-color: transparent;
        color: #006762;
    }

.main-nav li:hover {
    background-color: transparent;
    color: #0056b3;
}

.company-name {
    width: 100%;
    text-align: center;
    margin: 0 0 20px 0;
    display: none;
}

.main-logo {
    min-width: 100px;
    margin: 0 auto;
    position: relative;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 25px;
    margin: 10px 0 0 0;
    text-align: center;
    padding: 0;
    width: 100%;
    padding-left: 4%;
}

/*.main-logo__home {
    min-width: 100px;
    margin: 0 auto;
    position: relative;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 25px;
    margin: 10px 0 10px 0;
    text-align: center;
    padding: 0;
    width: 235px;
    position: absolute;
    top: 46px;
    left: 70px;
}*/

/*.site-name {
    margin: 0;
    padding-bottom: 0px;
    padding-top: 45px;
    padding-left: 85px;
}*/

.site-name {
    margin: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 210px;
}

.main-nav .header-contact-info {
    position: relative;
}

.header-contact-info a {
    display: inline-flex;
}

.header-contact-info i, .header-contact-info p {
    display: block;
    justify-self: center;
    align-self: center;
    color: #003219;
    font-size: 16px;
    box-sizing: border-box;
}

.archive-wrap {
    width: 100%;
    min-height: 400px;
    text-align: center;
}

.archive-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin: 0 auto;
    grid-gap: 10px;
    width: 70%;
}

.latest-grid img {
    margin: 5px;
}

.archivePagination {
    margin: 50px;
}

    .archivePagination a {
        padding: 20px;
    }

        .archivePagination a.active {
            background: #efefef;
        }

.archive-image {
    background-position: center;
    background-size: cover;
    min-height: 350px;
    max-height: 350px;
    max-width: 350px;
}

.archive-single h4, .archive-single p {
    text-align: left;
    margin: 10px 0;
}

.categories-wrap {
    width: 100%;
    min-height: 400px;
    text-align: center;
}

.category-grid {
    display: inline-flex;
    flex-wrap: wrap;
}

    .category-grid img {
        margin: 5px;
    }

.featured-wrap {
    width: 100%;
    text-align: center;
}

.featured-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

    .featured-grid img {
        max-width: 450px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

.latest-wrap {
    width: 100%;
    min-height: 400px;
    text-align: center;
}

.latest-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin: 0 auto;
    grid-gap: 10px;
    width: 70%;
}

    .latest-grid img {
        margin: 5px;
    }

.single-category {
    position: relative;
}

    .single-category img {
        max-width: 350px;
    }

    .single-category h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url(../images/watercolor.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 40px;
        color: white;
        font-weight: 400;
    }

.single-featured {
    position: relative;
    float: left;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 700px;
    display: grid;
    width: 100%;
}

    .single-featured h2 {
        align-self: center;
        justify-self: center;
        background: #4ca994;
        padding: 10px 40px;
        color: white;
        font-weight: 400;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .single-featured h3 {
        background: none;
    }

    .single-featured a {
        opacity: 1;
        transition: ease-in-out 0.3s;
    }

        .single-featured a:hover {
            opacity: 0.9;
        }


.single-latest h4, .single-latest p {
    text-align: left;
    margin: 10px 0;
}

.single-latest img {
    background-position: center;
    background-size: cover;
    min-height: 350px;
    max-height: 350px;
    max-width: 350px;
}

a {
    text-decoration: none;
    color: inherit;
}

﻿.single-product-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 70%;
    margin: 0 auto;
}

.add-to-cart-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 70%;
    margin: 0 auto;
}

.product-gallery {
    padding: 15px 0px;
    Align-self: start;
    justify-self: center;
}

    .product-gallery img {
        width: 100%;
    }

.product-description {
    text-align: left;
    padding: 0 15px 0 15px;
    max-width: 400px;
}

.single-product-gallery {
    max-width: 75%;
    margin: auto;
}

    .single-product-gallery img {
        max-height: 443.33px;
        width: 332.5px;
    }


.addbtn {
    width: 100%;
    padding: 10px 0px;
    background-color: #b3bfed;
    text-align: center;
    position: relative;
    float: left;
    margin: 5px 0px;
    transition: ease-in-out 0.5s;
    cursor: pointer;
    color: white;
}

    .addbtn:hover {
        background-color: #838fb7;
    }

.selectvariant {
    width: 100%;
    position: relative;
    float: left;
}

.selectquantity {
    width: 100%;
    position: relative;
    float: left;
}

h6 {
    position: relative;
    float: left;
    padding: 5px 0px !important;
    margin: 0;
}

.add-to-cart-message {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 0;
    color: #adaf44;
    opacity: 0;
}

.social-contact .social-icons {
    color: white;
    padding: 10px;
    box-sizing: border-box;
    width: auto;
    display: inline-block;
}

.social-icons a i {
    font-size: 35px;
    margin: 0px 5px;
    box-sizing: border-box;
}

.social-icons a {
    font-size: 14pt;
    margin: 0px 5px;
    box-sizing: border-box;
    display: flex;
    text-decoration: none;
}

    .social-icons a svg {
        margin-right: 3%;
        /*border: solid 1px;
        border-radius: 50%;*/
        margin-top: 1%;
    }

.social-icons .non-hollow-icon svg {
    padding-top: 3px;
    padding-bottom: 3px;
}

.social-icons span {
    font-size: 14pt !important;
    font-weight: normal !important;
}

.social-icons.social-icons--navbar {
    padding-right: 10px;
    display: inline;
}

    .social-icons.social-icons--navbar a {
        display: inline-block;
        color: #1e4f78;
        vertical-align: middle;
    }

        .social-icons.social-icons--navbar a:hover {
            color: #01857b;
        }

    .social-icons.social-icons--navbar svg {
        font-size: 38px;
    }

    .social-icons.social-icons--navbar span {
        display: none;
    }

.thankyou-container {
    text-align: center;
    margin-bottom: 2%;
}

/********************* Form.js **************************/
.form-component-container {

}

    .form-component-container .grid-wrapper {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .form-component-container .dual-grid-offset {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 5px;
        max-width: 90%;
        margin: 0 auto;
        width: 100%;
    }

    .form-component-container .form-title {
        text-align: center;
    }

    .form-component-container .form-submit {
        text-align: center;
        margin-top: 5px;
    }

    .form-component-container input,
    .form-component-container select {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .form-component-container .checkbox-wrapper {
        margin-right: 91%;
    }

    .form-component-container .submit-btn {
        background-color: #095587;
        color: white;
        padding: 15px;
    }

    .form-component-container textarea {
        height: unset;
        min-height: unset;
        resize: vertical;
    }

    .form-component-container input.file-input {
        border: none;
        padding-left: 0;
    }

    .form-component-container .required-flag {
        color: red;
        font-size: 28px;
        vertical-align: middle;
        font-weight: bold;
        padding-left: 5px;
    }

/********************* Contact5050.js **************************/
.contact5050 {

}

    .contact5050 .dual-grid__1-2 {
        grid-column: 1 /span 2;
    }

    .contact5050 .image-full-bg img {
        margin-top: 5%;
    }

    .contact5050 .content-left {
        margin-top: 30px;
    }

    .contact5050 hr {
        background: #e37051;
    }

.login-link {
    display: inline-flex;
    margin: 0 15px;
    margin-top: 5px;
    font-size: 28px;
}

.layout-container {
    margin: 0 10%;
}

.dynamic-pages-container {
    margin: 0 10%;
}

    .dynamic-pages-container .main-nav-wrap .site-name {
        color: white;
        font-size: 22px;
    }

    .dynamic-pages-container .page-name-header {
        text-align: center;
        color: #095587;
        font-size: 36pt;
        font-weight: bold;
        padding: 0px;
        margin-bottom: 1%;
    }

    .dynamic-pages-container .dynamic-pages-content-grid {
        display: grid;
        grid-template-columns: 5fr 1fr;
        grid-column-gap: 5%;
    }

    .dynamic-pages-container .sub-sidenav-component-wrapper {
        /*margin-right: 20%;*/
    }

/********************************SideNav.js*********************************************/
.side-nav-container {

}

    .side-nav-container .side-nav-content {
        /*border-right: solid 5px #e37051;
        margin-right: 10%;*/
    }

        .side-nav-container .side-nav-content h1.title {
            padding-top: 0px;
        }

.menu-items .dropdown .dropdown-btn {
    color: white;
    font-size: 24px;
}

    .menu-items .dropdown .dropdown-btn .right-caret-icon {
        font-size: 24px;
        color: white;
    }

.menu-items .dropdown .direct-dropdown-link:hover,
.menu-items .dropdown .direct-dropdown-link:focus {
    background-color: transparent;
}

.menu-items .dropdown .direct-dropdown-link a:hover,
.menu-items .dropdown .direct-dropdown-link a:focus {
    color: #006762;
}

.menu-items .nav-divider {
    color: black;
    padding-top: 1%;
    font-size: 28px;
}

.menu-items .sub-nav-divider {
    color: black;
    padding-top: 3%;
    font-size: 28px;
}

/******************************SideContainer.js********************************/
.side-container-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    max-width: 90%;
    margin: 5px auto;
}

    .side-container-container .side-content {
        box-shadow: 10px 0 7px -8px #888;
    }

/******************************IconLinkContainer.js********************************/
.icon-link-container-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    margin: 5% auto;
    text-align: center;
}

    .icon-link-container-container .icon-link-opt {
        margin: auto;
        /*border-bottom: 2px solid transparent;*/
        /*color: #1e4f78;*/
        font-size: 26px;
        font-weight: bold;
    }

        .icon-link-container-container .icon-link-opt:hover {
            /*border-bottom: 2px solid #01857b;
            color: #01857b;*/
            text-decoration: none;
        }

        .icon-link-container-container .icon-link-opt #payment_options,
        .icon-link-container-container .icon-link-opt #report_a_problem,
        .icon-link-container-container .icon-link-opt #garbage_fees {
            border-bottom: 2px solid transparent;
            color: #1e4f78;
        }

        .icon-link-container-container .icon-link-opt #payment_options_hover,
        .icon-link-container-container .icon-link-opt #report_a_problem_hover,
        .icon-link-container-container .icon-link-opt #garbage_fees_hover {
            border-bottom: 2px solid #01857b;
            color: #01857b;
        }

        .icon-link-container-container .icon-link-opt #pay_my_bill {
            border-bottom: 2px solid transparent;
            color: #01857b;
        }

        .icon-link-container-container .icon-link-opt #pay_my_bill_hover {
            border-bottom: 2px solid #1e4f78;
            color: #1e4f78;
        }

/******************************BlogPreview.js********************************/
.blog-preview-container {
    width: 90%;
    margin: 3% auto;
}

    .blog-preview-container .blog-preview-title {
        margin: 1% 0;
        font-size: 46px;
        font-weight: bold;
        color: #173668;
    }

    .blog-preview-container .blog-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        width: 100%;
        margin: 1% auto 2% auto;
    }

        .blog-preview-container .blog-grid .blog-snippet {
            height: 300px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 450px;
            cursor: pointer;
            text-decoration: none;
            width: 100%;
        }

            .blog-preview-container .blog-grid .blog-snippet .snippet-text {
                margin: 10px;
                background-color: white;
                padding: 10px;
                border-left: 10px solid #e16541;
                text-decoration: none;
                position: relative;
                top: 210px;
            }

                .blog-preview-container .blog-grid .blog-snippet .snippet-text .super-text {
                    color: #5a5a5a;
                    font-weight: bold;
                }

                .blog-preview-container .blog-grid .blog-snippet .snippet-text .sub-text {
                    color: #38517c;
                }

    .blog-preview-container .blog-preview-see-all {
        margin: 1% 0 5% 0;
    }

        .blog-preview-container .blog-preview-see-all a {
            font-size: 24px;
            color: white;
            background-color: #e16541;
            text-decoration: none;
            padding: 5px 50px;
            border-radius: 6px;
        }

/******************************ColumnsTabContainer.js********************************/
.columns-tab-container-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

    .columns-tab-container-container .tab-row {
        display: inline-flex;
        border-bottom: 10px solid #e16541;
        width: 100%;
        padding: 0 5px;
    }

        .columns-tab-container-container .tab-row.accordion {
            display: block;
            padding: 0 13px 0 5px;
        }

            .columns-tab-container-container .tab-row.accordion .tab {
                border-top-left-radius: unset;
                border-top-right-radius: unset;
            }

        .columns-tab-container-container .tab-row .tab {
            color: white;
            background-color: #5b96c8;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            font-size: 20px;
            margin: auto 3px 0 3px;
            padding: 3px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

            .columns-tab-container-container .tab-row .tab.current-tab {
                background-color: #e16541;
            }

    .columns-tab-container-container .current-tab-contents {

    }

        .columns-tab-container-container .current-tab-contents.show-border {
            border: 2px solid #e16541;
        }

        .columns-tab-container-container .current-tab-contents .tab-contents {
        }

            .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper {
                width: 100%;
                padding: 4% 30px;
            }

                .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container {
                    display: grid;
                    grid-gap: 30px;
                    width: 100%;
                }

                    .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-1-1 {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }

                    .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-1-1 {
                        grid-template-columns: 2fr 1fr 1fr;
                    }

                    .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-2 {
                        grid-template-columns: 1fr 1fr 2fr;
                    }

                    .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-2 {
                        grid-template-columns: 2fr 2fr;
                    }

                    .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4 {
                        grid-template-columns: 4fr;
                    }

            .columns-tab-container-container .current-tab-contents .tab-contents .footer-container {
                color: white;
                background-color: #5b96c8;
                padding: 3%;
            }

            .columns-tab-container-container .current-tab-contents .tab-contents button {
                color: white;
                background-color: #e16541;
                border-radius: 3px;
            }

            .columns-tab-container-container .current-tab-contents .tab-contents hr {
                color: #e16541;
                background-color: #e16541;
                border: none;
                height: 6px;
                margin-right: 65%;
            }

            .columns-tab-container-container .current-tab-contents .tab-contents p {
                margin-bottom: 0;
            }

/******************************NavbarTabContainer.js********************************/
.navbar-tab-wrapper {
    /*width: 125%;
    margin-left: -12.5%;*/
    width: auto;
}

    .navbar-tab-wrapper .navbar-tab-row-wrapper {
        background-color: #e37051;
    }

.navbar-tab-container {
    width: 80%;
    margin: auto;
}

    .navbar-tab-container .tab-row {
        display: inline-flex;
        width: 100%;
        padding: 0 5px;
    }

        .navbar-tab-container .tab-row .tab {
            color: white;
            font-size: 20px;
            margin: auto 3px 0 3px;
            padding: 3px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

            .navbar-tab-container .tab-row .tab:hover {
                text-decoration: underline;
            }

            .navbar-tab-container .tab-row .tab.current-tab {
                text-decoration: underline;
                background-color: #5b96c8;
            }

    .navbar-tab-container .current-tab-contents {
    }

        .navbar-tab-container .current-tab-contents .tab-contents {
        }

            .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper {
                width: 100%;
                padding: 1% 30px;
            }

                .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container {
                    display: grid;
                    grid-gap: 30px;
                    width: 100%;
                }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-1-1 {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-1-1 {
                        grid-template-columns: 2fr 1fr 1fr;
                    }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-2 {
                        grid-template-columns: 1fr 1fr 2fr;
                    }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-2 {
                        grid-template-columns: 2fr 2fr;
                    }

            .navbar-tab-container .current-tab-contents .tab-contents .footer-container {
                color: white;
                background-color: #5b96c8;
                padding: 3%;
            }

            .navbar-tab-container .current-tab-contents .tab-contents button {
                color: white;
                background-color: #e16541;
                border-radius: 3px;
            }

            .navbar-tab-container .current-tab-contents .tab-contents hr {
                color: #e16541;
                background-color: #e16541;
                border: none;
                height: 6px;
                margin-right: 65%;
            }

            .navbar-tab-container .current-tab-contents .tab-contents p {
                margin-bottom: 0;
            }

/**************************************Accordion**************************************************/
.accordion-container {
    /*padding: 10px 5%;*/
    padding: 10px;
}

    .accordion-container .tab {
        margin-bottom: 5px;
    }

        .accordion-container .tab .tab-header {
            background-color: #5b96c8;
            cursor: pointer;
            padding: 10px 15px;
            color: white;
            font-size: 20px;
        }

            .accordion-container .tab .tab-header:hover {
                /*background-color: #a8b4db;*/
                background-color: #a2cfef;
            }

            .accordion-container .tab .tab-header.accordion-header--open {
                /*background-color: #a8b4db;*/
                background-color: #a2cfef;
            }

            .accordion-container .tab .tab-header .tab-title {
            }

            .accordion-container .tab .tab-header .toggle-icon {
                float: right;
            }

        .accordion-container .tab .tab-content {

        }

            .accordion-container .tab .tab-content.accordion-content--close {
                display: none;
            }

            .accordion-container .tab .tab-content.accordion-content--open {
                display: block;
                background-color: #f1f1f1;
                padding: 10px 30px;
            }

/**************************************Static Breadcrumb**************************************************/

.static-breadcrumb-wrap {
    /*padding: 0 10%;*/
}

    .static-breadcrumb-wrap .static-breadcrumb {
        background-color: transparent !important;
        margin-bottom: 0;
        padding: 0;
        font-size: 10px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        border-radius: 0.25rem;
    }

        .static-breadcrumb-wrap .static-breadcrumb a,
        .static-breadcrumb-wrap .static-breadcrumb span {
            padding: 0 3px 0 3px;
        }

/******************************This class can be used as a wrapper around any HTML editor content*********************************************/
.general-html-editor-wrapper {
    
}

    .general-html-editor-wrapper table {
        overflow-wrap: anywhere;
    }

/***************************************Small Laptop/Tablet***************************************************/
@media only screen and (max-width: 1400px) {
    .dynamic-pages-container {
        width: auto;
    }

        .dynamic-pages-container .sub-sidenav-component-wrapper {
            margin-right: 0;
        }

    .html-editor-wrap {
        width: 100%;
    }

    .contact5050 .image-full-bg img {
        width: 100%;
    }

    .checkout-button-wrap {
        width: 230px;
    }

    .cart-subtotal h3:nth-child(2) {
        float: left;
    }

    .grid5050 .featured-wrapper {
        text-align: center;
    }

    .single-product-wrap {
        grid-template-columns: 1fr;
    }

        .single-product-wrap .product-description {
            padding: 0;
            max-width: none;
        }

    .single-product-gallery img {
        width: 100%;
    }

    .grid5050 .grid5050-image {
        width: 100%;
    }

    .html-editor-wrap iframe {
        width: 100%;
    }

    .footer-wrap {
        min-height: 125px;
    }

        .footer-wrap .footer .footer-menu-items {
            display: none;
        }

    .breadcrumb-wrap {
        width: 75%;
    }

    ul {
        
    }

    .action-area {
        width: 80%;
    }

    .main-nav-wrap {
        grid-template-columns: 1fr 3fr;
        box-sizing: border-box;
        height: auto;
    }

        .main-nav-wrap .social-contact {
            display: none;
        }

    .main-logo-wrapper {

    }

    .main-logo {
        margin: auto;
        padding: 0;
        padding-top: 5px;
        width: 75%;
    }

    .main-nav-wrap .nav-extras .nav-extras-main .pay-bill-button {
        font-size: 20px;
        padding: 18px 15px 15px 15px;
    }

    .mobile-nav {
        display: block;
        z-index: 200;
        position: fixed;
        width: 60%;
        transform: translateX(-100%);
        transition: ease-in 0.3s;
        height: 100%;
        top: 0;
        left: 0;
    }

    .header-contact-info i {
        padding-left: 0;
    }

    .mobile-nav-button {
        display: none;
        
    }

    .mobile-nav ul {
        display: grid;
        position: fixed;
        background: #5397d2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-shadow: 2px 0 10px rgba(0,0,0,0.4);
        padding: 0;
    }

        .mobile-nav ul li {
            color: white;
        }

    .open.mobile-nav {
        transform: translateX(0);
    }

    .header-contact-info i, .header-contact-info p {
        padding-left: 0px;
    }

    .archive-grid {
        grid-template-columns: repeat(2, 1fr);
        width: 75%;
    }

    .category-grid {
        display: grid;
        grid-template-columns: 1fr;
        width: 95%;
        margin: 0 auto;
    }

    .featured-grid {
        grid-template-columns: 1fr;
    }

    .latest-grid {
        grid-template-columns: repeat(2, 1fr);
        width: 75%;
    }

    .blog-preview-container .blog-grid .blog-snippet .snippet-text {
        top: 180px;
    }

    .home-announce {
        grid-template-columns: 2fr 10fr 1fr;
    }

        .home-announce .announcement-title {
            font-size: 26px;
        }

        .home-announce .announcement-remove {
            font-size: 26px;
        }

        .home-announce .announcement-content {
            text-align: center;
            font-size: 14px;
            padding: 5px 5px 0 5px;
        }

    .accordion-container {
        padding: 10px 0px;
    }

    .blog-container {

    }

        .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet .snippet-text {
            top: 180px;
        }

        .blog-container img {
            width: 100%;
            height: auto;
        }

    .event-container {

    }

        .event-container img {
            width: 100%;
            height: auto;
        }

    .general-html-editor-wrapper table.mobile-full-row td {
        height: auto !important;
        display: block;
        width: 99.9% !important;
        clear: both;
    }
}

/***************************************Phone***************************************************/
@media only screen and (max-width: 1000px) {
    .dynamic-pages-container {
        width: auto;
    }

        .dynamic-pages-container .dynamic-pages-content-grid {
            grid-template-columns: 1fr;
        }

        .dynamic-pages-container .page-name-header {
            font-size: 26pt;
            word-break: break-word;
        }

    .full-width-wrapper__phone {
        width: 125%;
        margin-left: -12.5%;
    }

    .breadcrumb-wrap {
        width: 95%;
    }

    .archive-grid {
        grid-template-columns: repeat(1, 1fr);
        width: 95%;
    }

    .latest-grid {
        width: 95%;
    }

    .single-product-wrap {
        margin: 0 20%;
    }

    .single-product-gallery img {
        width: 100%;
    }

    .product-description h1 {
        font-size: 28px;
        text-align: center;
    }

    .cart-wrap .cart {
        grid-template-columns: 1fr;
    }

    .mobile-nav ul {
        font-size: 18px;
    }

    .mobile-nav .toggle-icon {
        font-size: 30px !important;
    }

    .sub-nav {
        display: none;
    }

    .main-nav-wrap {
        padding: 5px 0 !important;
        grid-template-columns: 2fr 1fr;
    }

        .main-nav-wrap .mobile-nav-button {
            display: block;
            position: relative;
            font-size: 30px;
            color: #006f66;
            padding: 10px 50%;
            padding: 0;
            margin: auto;
        }

        .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container {
            display: none;
        }

        .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container {
            display: none;
        }

        .main-nav-wrap .nav-extras .nav-extras-sub {
            display: none;
        }

        .main-nav-wrap .nav-extras {
            display: none;
        }

        .main-nav-wrap .nav-content-container {
            grid-row: span 2/ 1;
        }

        .main-nav-wrap .main-logo {
            float: left;
        }

        .main-nav-wrap__super .mobile-sub-nav-container {
            display: block;
            width: 100%;
        }

            .main-nav-wrap__super .mobile-sub-nav-container .pay-bill-button--mobile-wrapper {
            }

                .main-nav-wrap__super .mobile-sub-nav-container .pay-bill-button--mobile-wrapper .pay-bill-button--mobile {
                    background-color: #006f66;
                    color: white;
                    text-transform: capitalize;
                    font-size: 20px;
                    font-weight: bold;
                    text-decoration: none;
                    padding: 10px;
                    text-align: center;
                    display: block;
                    width: 100%;
                }

    .main-nav {
        display: none;
    }

    .main-logo-wrapper {
        width: 80%;
        grid-column: span 1/ 2;
    }

    .home-announce {
        padding: 0;
        margin: 0;
        grid-template-columns: 3fr 6fr 0.5fr;
    }

        .home-announce .announcement-title {
            background-image: -webkit-linear-gradient( 165deg, #5397d2 35%, #e16541 35%);
        }

        .home-announce .announcement-remove {
            
        }

        .home-announce .announcement-content {
            text-align: left;
            font-size: 14px;
            padding: 5px 5px 0 5px;
        }

    .icon-link-container-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        margin: 0 40px;
        width: auto;
    }

        .icon-link-container-container img {
            width: 70%;
        }

        .icon-link-container-container .icon-link-opt {
            font-size: 20px;
            margin: 0;
        }

            .icon-link-container-container .icon-link-opt.payment-options img {
                width: 95%;
            }

            .icon-link-container-container .icon-link-opt.garbage-fees img {
                width: 60%;
            }

            .icon-link-container-container .icon-link-opt.pay-my-bill img {
                width: 74%;
            }

    .columns-tab-container-container .tab-row {
        display: block;
        padding: 0px 7px 0px 1px;
    }

        .columns-tab-container-container .tab-row .tab {
            border-top-left-radius: unset;
            border-top-right-radius: unset;
        }

    .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper {
        padding: 2%;
    }

        .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-1-1,
        .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-1-1,
        .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-2,
        .columns-tab-container-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-2
        {
            grid-template-columns: 1fr;
        }

    .navbar-tab-wrapper {
        width: 100%;
        margin: auto;
    }

    .navbar-tab-container {
        width: 100%;
    }

        .navbar-tab-container .tab-row {
            display: block;
            padding: 0px 7px 0px 1px;
        }

            .navbar-tab-container .tab-row .tab.current-tab {
                text-decoration: underline;
                background-color: #5b96c8;
            }

        .navbar-tab-container .current-tab-contents {
            width: 80%;
            margin: auto;
        }

    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper {
        padding: 2%;
    }

        .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-1-1,
        .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-1-1,
        .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__1-1-2,
        .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__2-2 {
            grid-template-columns: 1fr;
        }

    .blog-preview-container {
        text-align: center;
    }

        .blog-preview-container .blog-grid {
            grid-template-columns: 1fr;
        }

            .blog-preview-container .blog-grid .blog-snippet .snippet-text {
                top: 210px;
                width: 75%;
                margin: 10px auto;
            }

            .blog-preview-container .blog-grid .blog-snippet {
                background-position: bottom;
            }

    .footer-wrap .super-footer-container .super-footer-header-wrapper {
        display: none;
    }

    .footer-wrap .super-footer-container .super-footer-content {
        margin: 0 auto;
        grid-template-columns: 1fr;
        width: 90%;
        text-align: center;
    }

        .footer-wrap .super-footer-container .super-footer-content form .submit-btn-wrapper input {
            width: auto;
        }

        .footer-wrap .footer-column-grid {
            grid-template-columns: 1fr;
        }

        .footer-wrap .footer-column-grid div {
            padding: 1%;
        }

            .footer-wrap .footer-column-grid div p {
                text-align: center !important;
            }

    .footer-wrap .footer-social-column {
        text-align: center;
        margin: auto;
    }

        .footer-wrap .footer-social-column .social-icons svg {
            margin-left: 17%;
            margin-right: 3%;
        }

    .footer-wrap .legal-info span {
        padding: 2px;
        display: block;
    }

    .blog-table-container {
        
    }

        .blog-table-container.blog-table-container__full .blog-filter-grid {
            grid-template-columns: 1fr;
            grid-gap: 10px;
        }

        .blog-table-container .blog-table-grid {
            grid-template-columns: 1fr;
            grid-gap: 20px 10px;
        }

    .document-table-container {
        overflow: scroll;
    }

        .document-table-container .table-container .custom-search-bar-wrapper .custom-search-bar {
            float: unset;
            width: 100%;
        }

        .document-table-container .table-container .document-type-filter {
            position: relative;
            width: 100%;
            margin-bottom: 1%;
        }

    .general-html-editor-wrapper img {
        width: 100%;
        height: auto;
    }

    .general-html-editor-wrapper ul {
        display: grid;
    }

    .contact-input-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .form-component-container {
        
    }

        .form-component-container .dual-grid-offset {
            grid-template-columns: 1fr;
        }

        .form-component-container .dual-grid-offset label {
            margin-bottom: 0;
        }

        .form-component-container #reCaptchaWrapper {
            overflow-x: scroll;
        }

    .blog-container .other-blog-preview-container {
        text-align: center;
    }

        .blog-container .other-blog-preview-container .other-blog-grid {
            grid-template-columns: 1fr;
        }

            .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet .snippet-text {
                top: 210px;
                width: 75%;
                margin: 10px auto;
            }

            .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet {
                background-position: bottom;
            }
}

@media only screen and (max-width: 500px) {
    .blog-preview-container .blog-grid .blog-snippet .snippet-text {
        top: auto;
    }

    .blog-container .other-blog-preview-container .other-blog-grid .blog-snippet .snippet-text {
        top: auto;
    }
}

@media only screen and (max-width: 445px) {
    .event-table-container .event-calendar-container .rbc-toolbar-label {
        text-align: center;
    }

    .event-table-container .checkbox-wrapper {
        text-align: center;
    }
}
