.birthday-management-container {
    text-align: center;
}

    .birthday-management-container .grid-wrapper {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .birthday-management-container .dual-grid {
        display: grid;
        grid-template-columns: 4fr 2fr;
        grid-gap: 5px;
        margin: 0 auto;
        width: 95%;
    }

    .birthday-management-container.form-form--update .dual-grid {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 5px;
        margin: 0 auto;
        width: 95%;
    }

    .birthday-management-container .dual-grid__1 {
        grid-column: 1;
    }

    .birthday-management-container .dual-grid__1-2 {
        grid-column: 1 /span 2;
    }

    .birthday-management-container .tri-grid-offset {
        display: grid;
        grid-template-columns: 2fr 4fr 1fr;
        grid-gap: 5px;
        max-width: 90%;
        margin: 0 auto;
        width: 90%;
    }

    .birthday-management-container .tri-grid__1-3 {
        grid-column: 1 /span 3;
    }

    .birthday-management-container.form-form--update .tri-grid-offset {
        grid-template-columns: 1fr 4fr;
        width: 95%;
    }

    .birthday-management-container .tri-grid-offset label {
        margin-top: 12px;
        text-align: left;
        padding-left: 30%;
        font-weight: bold;
    }

    .birthday-management-container.form-form--update .tri-grid-offset label {
        padding-left: 0;
    }

    .birthday-management-container .form-title {
        text-align: center;
    }

    .birthday-management-container .cancel-btn {
        background-color: #dd4b39;
        border-color: #da3a25;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        margin: 0 0 10px 10px;
        min-width: 50px;
        text-decoration: none;
    }

        .birthday-management-container .cancel-btn:hover,
        .birthday-management-container .cancel-btn:focus {
            background-color: #da3a25;
            border-color: #99291a;
        }

        .birthday-management-container .cancel-btn:active {
            background-color: #99291a;
            border-color: #57170f;
        }

        .birthday-management-container .cancel-btn:disabled {
            background-color: rgba(221, 75, 57, 0.5);
        }

    .birthday-management-container .form-button {
        background-color: #3c8dbc;
        border-color: #367fa9;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        /*margin: 0 0 10px 10px;*/
        min-width: 50px;
    }

        .birthday-management-container .form-button:hover,
        .birthday-management-container .form-button:focus {
            background-color: #367fa9;
            border-color: #204d74;
            text-decoration: none;
            outline: none;
        }

        .birthday-management-container .form-button:active {
            background-color: #204d74;
            border-color: #122b40;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

        .birthday-management-container .form-button:disabled,
        .birthday-management-container .form-button--inactive {
            background-color: rgba(60, 141, 188, 0.5);
            cursor: default;
            pointer-events: none;
        }

    .birthday-management-container .view-link {
        background-color: #3c8dbc;
        border-color: #367fa9;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        min-width: 50px;
        margin: auto;
    }

        .birthday-management-container .view-link:hover,
        .birthday-management-container .view-link:focus {
            background-color: #367fa9;
            border-color: #204d74;
            text-decoration: none;
            outline: none;
        }

        .birthday-management-container .view-link:active {
            background-color: #204d74;
            border-color: #122b40;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

    .birthday-management-container select,
    .birthday-management-container input {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

        .birthday-management-container input.input-file-field {
            border: none;
            padding-left: 0;
            padding-top: 10px;
        }

    .birthday-management-container .checkbox-container {
        display: inline-block;
        margin: auto;
        min-width: 33.3%;
    }

    .birthday-management-container .checkbox-wrapper {
        margin-right: 94%;
    }

    .birthday-management-container.form-form--update .checkbox-wrapper {
        margin-right: 79%;
    }

    .birthday-management-container .plain-text {
        margin-top: 12px;
        text-align: left;
        word-break: break-word;
    }

    .birthday-management-container .form-submit {
        margin-top: 5px;
    }

    .birthday-management-container .input-info-container {
        display: flex;
    }

        .birthday-management-container .input-info-container .char-counter {
            font-size: large;
            font-weight: bold;
            padding-top: 12px;
            padding-left: 5px;
        }

            .birthday-management-container .input-info-container .char-counter.good-count {
                color: #80ba54;
            }

            .birthday-management-container .input-info-container .char-counter.bad-count {
                color: red;
            }

        .birthday-management-container .input-info-container .info-icon {
            font-size: large;
            margin-top: 15px;
            margin-left: 5px;
        }

            .birthday-management-container .input-info-container .info-icon:hover {
                color: #80ba54;
            }
