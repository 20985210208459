.form-field-form-container .grid-wrapper {
    width: 100%;
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
}

.form-field-form-container .dual-grid-offset {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 5px;
    max-width: 90%;
    margin: 0 auto;
}

.form-field-form-container .dual-grid__1 {
    grid-column: 1;
}

.form-field-form-container .dual-grid__1-2 {
    grid-column: 1 /span 2;
}

.form-field-form-container .form-title {
    text-align: center;
}

.form-field-form-container button {
    background-color: #3c8dbc;
    border-color: #367fa9;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: white;
    padding: 12px 6px;
    font-weight: normal;
    font-size: 16px;
    /*margin: 0 0 10px 10px;*/
    min-width: 50px;
}

    .form-field-form-container button:hover,
    .form-field-form-container button:focus {
        background-color: #367fa9;
        border-color: #204d74;
        text-decoration: none;
        outline: none;
    }

    .form-field-form-container button:active {
        background-color: #204d74;
        border-color: #122b40;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

    .form-field-form-container button:disabled {
        background-color: rgba(60, 141, 188, 0.5);
        cursor: default;
        pointer-events: none;
    }

    .form-field-form-container button.delete-btn {
        background-color: #dd4b39;
        border-color: #da3a25;
        color: white;
        margin-left: 10px;
    }

        .form-field-form-container button.delete-btn:hover,
        .form-field-form-container button.delete-btn:focus {
            background-color: #da3a25;
            border-color: #99291a;
        }

        .form-field-form-container button.delete-btn:active {
            background-color: #99291a;
            border-color: #57170f;
        }

        .form-field-form-container button.delete-btn:disabled {
            background-color: rgba(221, 75, 57, 0.5);
        }

.form-field-form-container select,
.form-field-form-container input {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    height: 50px;
    color: rgba(46, 46, 46, .8);
    border: 1px solid rgb(225, 225, 225);
    outline: none;
    margin: 0 0 0 0;
    padding-left: 15px;
    padding-right: 15px;
    resize: none;
}

.form-field-form-container .page-order-container {
    margin: auto 25%;
}

    .form-field-form-container .page-order-container .comment-text {
        font-size: 14px;
    }

.form-field-form-container label {
    margin-top: 12px;
    text-align: left;
    padding-left: 0;
    font-weight: bold;
}

.form-field-form-container textarea {
    min-height: unset;
    height: unset;
    resize: vertical;
}

.form-field-form-container .checkbox-wrapper {
    margin-right: 94%;
}
