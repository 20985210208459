/* The Update version of the page form has different css than the main form. It is dynamically applied on the form with the class page-form--update */

.page-form {
    text-align: center;
}

.page-form .grid-wrapper {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.page-form .dual-grid {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 5px;
    margin: 0 auto;
    width: 95%;
}

    .page-form.page-form--update .dual-grid {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 5px;
        margin: 0 auto;
        width: 95%;
    }

.page-form .dual-grid__1 {
    grid-column: 1;
}

.page-form .dual-grid__1-2 {
    grid-column: 1 /span 2;
}

.page-form .tri-grid-offset {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    grid-gap: 5px;
    max-width: 90%;
    margin: 0 auto;
    width: 90%;
}

    .page-form.page-form--update .tri-grid-offset {
        grid-template-columns: 1fr 4fr 1fr;
    }

.page-form .tri-grid-offset label {
    margin-top: 12px;
    text-align: left;
    padding-left: 30%;
    font-weight: bold;
}

    .page-form.page-form--update .tri-grid-offset label {
        padding-left: 0;
    }

.page-form .form-title {
    text-align: center;
}

    .page-form .cancel-btn {
        background-color: #dd4b39;
        border-color: #da3a25;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        margin: 0 0 10px 10px;
        min-width: 50px;
        text-decoration: none;
    }

    .page-form .cancel-btn:hover,
    .page-form .cancel-btn:focus {
        background-color: #da3a25;
        border-color: #99291a;
    }

    .page-form .cancel-btn:active {
        background-color: #99291a;
        border-color: #57170f;
    }

    .page-form .cancel-btn:disabled {
        background-color: rgba(221, 75, 57, 0.5);
    }

.page-form .form-button {
    background-color: #3c8dbc;
    border-color: #367fa9;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: white;
    padding: 12px 6px;
    font-weight: normal;
    font-size: 16px;
    /*margin: 0 0 10px 10px;*/
    min-width: 50px;
}

    .page-form .form-button:hover,
    .page-form .form-button:focus {
        background-color: #367fa9;
        border-color: #204d74;
        text-decoration: none;
        outline: none;
    }

    .page-form .form-button:active {
        background-color: #204d74;
        border-color: #122b40;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

    .page-form .form-button:disabled,
    .page-form .form-button--inactive {
        background-color: rgba(60, 141, 188, 0.5);
        cursor: default;
        pointer-events: none;
    }

.page-form select,
.page-form input {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    height: 50px;
    color: rgba(46, 46, 46, .8);
    border: 1px solid rgb(225, 225, 225);
    outline: none;
    margin: 0 0 0 0;
    padding-left: 15px;
    padding-right: 15px;
    resize: none;
}

    .page-form .checkbox-container {
        display: inline-block;
        margin: auto;
        min-width: 33.3%;
    }

    .page-form .checkbox-wrapper {
        margin-right: 92%;
    }

        .page-form.page-form--update .checkbox-wrapper {
            margin-right: 92%;
        }

    .page-form .plain-text {
        margin-top: 12px;
        text-align: left;
        word-break: break-word;
    }

    .page-form .input-info-container {
        display: flex;
    }

        .page-form .input-info-container .char-counter {
            font-size: large;
            font-weight: bold;
            padding-top: 12px;
            padding-left: 5px;
        }

            .page-form .input-info-container .char-counter.good-count {
                color: #80ba54;
            }

            .page-form .input-info-container .char-counter.bad-count {
                color: red;
            }

        .page-form .input-info-container .info-icon {
            font-size: large;
            margin-top: 15px;
            margin-left: 5px;
        }

            .page-form .input-info-container .info-icon:hover {
                color: #80ba54;
            }

.page-form .disable-wrapper {
    pointer-events: none !important;
}

.page-form.disable-wrapper button,
.page-form.disable-wrapper select,
.page-form.disable-wrapper input,
.page-form.disable-wrapper textarea {
    pointer-events: none !important;
}

.page-form.disable-wrapper select,
.page-form.disable-wrapper input,
.page-form.disable-wrapper textarea {
    background-color: rgb(247, 247, 247);
}

.page-form.disable-wrapper button.delete-btn {
    background-color: rgba(221, 75, 57, 0.5);
}

.page-form.disable-wrapper button {
    border-color: #122b40;
    background-color: rgba(60, 141, 188, 0.5);
}

.page-form .editor-wrapper.disable-wrapper .tox-tbtn {
    background-color: rgb(247, 247, 247);
}

    .page-form .delete-page-btn {
        background-color: #dd4b39;
        border-color: #da3a25;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        min-width: 50px;
    }

        .page-form .delete-page-btn:hover,
        .page-form .delete-page-btn:focus {
            background-color: #da3a25;
            border-color: #99291a;
            text-decoration: none;
            outline: none;
        }

        .page-form .delete-page-btn:active {
            background-color: #99291a;
            border-color: #57170f;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

    .page-form .restore-page-btn {
        background-color: #00cc44;
        border-color: #00b33c;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        min-width: 50px;
    }

        .page-form .restore-page-btn:hover,
        .page-form .restore-page-btn:focus {
            background-color: #00b33c;
            border-color: #009933;
            text-decoration: none;
            outline: none;
        }

        .page-form .restore-page-btn:active {
            background-color: #009933;
            border-color: #00802b;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }
