.container {
    text-align: center;
    margin-top: 5%;
}

.container .input-container {
    margin-bottom: 1%;
}

.container .input-container i {
    margin-right: 0.5%;
}

.error-message {
    color: red;
}