.form-builder-management-container {
    width: 90%;
    margin: auto;
}

.form-builder-management-container .forms-table-wrapper table {
    width: 100%;
    margin: auto;
}

.form-builder-management-container .forms-table-wrapper th {
    text-align: left;
}

.form-builder-management-container .forms-table-wrapper tr {
}

.form-builder-management-container .page-title {
    text-align: center;
}

.form-builder-management-container .update-btn:hover {
    cursor: pointer;
    color: #80BA54;
}

    .form-builder-management-container .view-btn:hover {
        cursor: pointer;
        color: #007bff;
    }

.form-builder-management-container .delete-btn:hover {
    cursor: pointer;
    color: red;
}

.form-builder-management-container .form-management-button {
    background-color: #3c8dbc;
    border-color: #367fa9;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: white;
    padding: 12px 6px;
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 10px 10px;
    min-width: 50px;
}

    .form-builder-management-container .form-management-button:hover,
    .form-builder-management-container .form-management-button:focus {
        background-color: #367fa9;
        border-color: #204d74;
        text-decoration: none;
        outline: none;
    }

    .form-builder-management-container .form-management-button:active {
        background-color: #204d74;
        border-color: #122b40;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

.form-builder-management-container .table-container thead[data-test="datatable-head"] th {
    cursor: pointer;
}

.form-builder-management-container .table-container tbody tr:hover {
    background-color: lightgrey;
}

.form-builder-management-container .check-icon {
    color: royalblue;
}

.form-builder-management-container .times-icon {
    color: red;
}

.form-builder-management-container .table-container thead th:nth-child(7),
.form-builder-management-container .table-container tbody td:nth-child(7),
.form-builder-management-container .table-container thead th:nth-child(8),
.form-builder-management-container .table-container tbody td:nth-child(8) {
    text-align: center;
}
