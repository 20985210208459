.page-component-form .grid-wrapper {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.page-component-form .tri-grid {
    display: grid;
    /*grid-template-columns: 20% 40% 40%;*/
    grid-template-columns: 20% 80%;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
}

.page-component-form .tri-grid--full-center {
    grid-column: span 3;
    text-align: center;
}

.page-component-form .current-tab {
    background-color: rgb(250, 250, 250);
}

.page-component-form .column-tabs button {
    outline: none;
    border-left: #bfbfbf 1px solid;
    border-top: #bfbfbf 1px solid;
    box-shadow: inset 0px -10px 10px -10px #888;
}

    .page-component-form .column-tabs button:last-of-type {
        border-right: #bfbfbf 1px solid;
    }

    .page-component-form .column-tabs button.current-tab {
        box-shadow: 0px -10px 10px -10px #888, 10px -10px 10px -10px #888, -10px -10px 10px -10px #888;
        position: relative;
    }

.page-component-form .left-column {
    text-align: left;
    font-weight: 600;
    color: black;
}

    .page-component-form .left-column button {
        width: 100%;
        padding: 10px;
    }

        .page-component-form .left-column button:hover {
            background-color: #bbb;
        }

        .page-component-form .left-column button:focus {
            outline: none;
        }

    .page-component-form .left-column h3 {
        text-align: center;
        padding-bottom: 2px;
    }

    .page-component-form .left-column .current-component {
        padding: 5px 10px;
    }

        .page-component-form .left-column .current-component.disable-dnd {
            background-color: #6894b1;
        }

    .page-component-form .left-column .current-component__drag-icon {
        margin-right: 10px;
        margin-bottom: 4%;
    }

    .page-component-form .left-column .current-component__data {
        display: inline-block;
    }

    .page-component-form .left-column .current-component__manage {
        float: right;
        margin-top: 4%;
    }

    .page-component-form .left-column .current-component__edit {
        padding-left: 5px;
        padding-right: 5px;
    }

        .page-component-form .left-column .current-component__edit:hover {
            cursor: pointer;
            color: #80BA54;
        }

    .page-component-form .left-column .current-component__delete {
        padding-left: 5px;
        padding-right: 5px;
    }

        .page-component-form .left-column .current-component__delete:hover {
            cursor: pointer;
            color: red;
        }

    .page-component-form .left-column .current-component__view {
        padding-left: 5px;
        padding-right: 5px;
    }

        .page-component-form .left-column .current-component__view:hover {
            cursor: pointer;
            color: #007bff;
        }

.page-component-form .center-column {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

    .page-component-form .center-column .component-type-choice select {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .page-component-form .center-column .component-type-choice .component-type-ddl {
        width: 50%;
    }

    .page-component-form .center-column .component-type-choice .component-type-choice--grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        grid-gap: 10px;
    }

        .page-component-form .center-column .component-type-choice .component-type-button {
            padding: 0;
            background-color: transparent;
            opacity: 0.8;
        }

            .page-component-form .center-column .component-type-choice .component-type-button:hover {
                opacity: 1;
            }

            .page-component-form .center-column .component-type-choice .component-type-button span {
                display: block;
            }

            .page-component-form .center-column .component-type-choice .component-type-button img {
                width: 125px;
                height: 80px;
            }

    .page-component-form .center-column .intro-data span {
        font-size: x-large;
    }

    .page-component-form .center-column .intro-data .explination-container {
        padding-left: 7%;
        padding-right: 7%;
    }

    .page-component-form .center-column .existing-component {
        padding-left: 25%;
        padding-right: 25%;
    }

        .page-component-form .center-column .existing-component button {
            width: 100%;
        }

            .page-component-form .center-column .existing-component button:hover {
                background-color: #bbb;
            }

.page-component-form .right-column {
    text-align: left;
}

    .page-component-form .right-column h3 {
        text-align: center;
    }

.page-component-form .page-title h2 {
    font-weight: bold;
}

.page-component-form .selected-component {
    color: yellow;
}

.page-component-form .version-container {
    padding: 3px 3px 0 3px; 
    background-color: rgb(239, 239, 239);
    text-align: center;
}

    .page-component-form .version-container .version-select {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 34px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .page-component-form .version-container .version-button-container {
        display: inline-flex;
        padding-top: 3px;
        width: 100%;
    }

        .page-component-form .version-container .version-button-container button {
            margin: auto 10px;
        }

.page-component-form .preview-button {
    background-color: #3c8dbc;
    border-color: #367fa9;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: white;
    padding: 12px 6px;
    font-weight: normal;
    font-size: 16px;
    /*margin: 0 0 10px 10px;*/
    min-width: 50px;
}

    .page-component-form .preview-button:hover,
    .page-component-form .preview-button:focus {
        background-color: #367fa9;
        border-color: #204d74;
        text-decoration: none;
        outline: none;
    }

    .page-component-form .preview-button:active {
        background-color: #204d74;
        border-color: #122b40;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }
