.tracsoft-admin-settings {
    text-align: center;
}

    .tracsoft-admin-settings .grid-wrapper {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .tracsoft-admin-settings .dual-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        max-width: 90%;
        margin: 0 auto;
        width: 90%;
    }

    .tracsoft-admin-settings .dual-grid--offset {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 5px;
        margin: 0 auto;
        width: 90%;
    }

    .tracsoft-admin-settings .dual-grid__1 {
        grid-column: 1;
    }

    .tracsoft-admin-settings .dual-grid__1-2 {
        grid-column: 1 /span 2;
    }

    .tracsoft-admin-settings .form-title {
        text-align: center;
    }

    .tracsoft-admin-settings .form-button {
        background-color: #3c8dbc;
        border-color: #367fa9;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        min-width: 100px;
    }

        .tracsoft-admin-settings .form-button:hover,
        .tracsoft-admin-settings .form-button:focus {
            background-color: #367fa9;
            border-color: #204d74;
            text-decoration: none;
            outline: none;
        }

        .tracsoft-admin-settings .form-button:active {
            background-color: #204d74;
            border-color: #122b40;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

        .tracsoft-admin-settings .form-button:disabled,
        .tracsoft-admin-settings .form-button--inactive {
            background-color: rgba(60, 141, 188, 0.5);
            cursor: default;
            pointer-events: none;
        }

    .tracsoft-admin-settings select,
    .tracsoft-admin-settings input {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .tracsoft-admin-settings .checkbox-container {
        min-width: 100%;
    }

    .tracsoft-admin-settings .module-wrapper {
        margin: 1%;
    }

    .tracsoft-admin-settings .dual-grid--offset label {
        font-weight: bold;
        margin-top: 2%;
        text-align: left;
    }
