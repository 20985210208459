html, body {
    height:100vh;
    margin:0px;
    box-sizing:border-box;
}

.admin{
    height:100%;
    display:block;
    position:relative;
}

.admin.container {
    text-align: left;
    width:20%;
    float:left;
    position:relative;
    display:block;
    background:#0AAEB7;
    height:100vh;
    margin:0;
}

.admin.nav-button {
    background-color:transparent;
    color: white;
    padding: 5px 15px;
    text-align: left;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    border: none;
    margin: 5px;
    max-width:100%;
}

    .admin.nav-button:hover,
    .admin.nav-button:focus {

        color: white;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        margin: 5px;
    }

    .admin.nav-button:active {

        color: white;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        transform: translateY(1px);
        margin: 5px;
    }

    .admin.nav-button:disabled,
    .admin.nav-button--inactive {
        color: white;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        cursor: default;
        border: none;
        pointer-events: none;
        margin: 5px;
    }

.credentialConfigContainer {
    margin-top: 3%;
}

    .credentialConfigContainer h3 {
        padding: 0px;
    }

.config-switch-button {
    background-color: #ff8080;
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Nunito Sans', "sans-serif";
    box-shadow: 2px 2px 10px #888;
    border: 2px outset lightgrey;
    border-radius: 25px;
    margin: 5px;
}

    .config-switch-button:hover,
    .config-switch-button:focus {
        background-color: #ff4d4d;
        color: white;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        font-family: 'Nunito Sans', "sans-serif";
        box-shadow: 2px 2px 10px #888;
        border: 2px outset lightgrey;
        border-radius: 25px;
        margin: 5px;
    }

    .config-switch-button:active {
        background-color: #ff4d4d;
        color: white;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        font-family: 'Nunito Sans', "sans-serif";
        box-shadow: -1px -1px 8px #888;
        border: 2px inset lightgrey;
        border-radius: 25px;
        transform: translateY(1px);
        margin: 5px;
    }
