.navbar-tab-form {

}

    .navbar-tab-form .tri-grid {
        display: grid;
        grid-template-columns: 1fr 4fr 0.5fr;
        grid-gap: 5px;
        max-width: 90%;
        margin: 0 auto;
    }

    .navbar-tab-form .tri-grid__1-3 {
        grid-column: 1 /span 3;
    }

.navbar-tabs-management-container {
    padding-bottom: 5px;
}

    .navbar-tabs-management-container .tab-management-btns .add-tab-button {
        cursor: pointer;
        padding-bottom: 8px;
        font-weight: bold;
        text-decoration: underline;
    }

        .navbar-tabs-management-container .tab-management-btns .add-tab-button:hover {
            background-color: #bbb;
        }

    .navbar-tabs-management-container .tabs {

    }

        .navbar-tabs-management-container .tabs .navbar-tab-opt {
            width: 100%;
            display: grid;
            grid-template-columns: 10fr 1fr;
        }

            .navbar-tabs-management-container .tabs .navbar-tab-opt .current-component__edit {
                padding-left: 5px;
                padding-right: 5px;
            }

                .navbar-tabs-management-container .tabs .navbar-tab-opt .current-component__edit:hover {
                    cursor: pointer;
                    color: #80BA54;
                }

            .navbar-tabs-management-container .tabs .navbar-tab-opt .current-component__delete {
                padding-left: 5px;
                padding-right: 5px;
            }

                .navbar-tabs-management-container .tabs .navbar-tab-opt .current-component__delete:hover {
                    cursor: pointer;
                    color: red;
                }