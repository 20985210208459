.page-version-logs-container .table-container table thead th:nth-child(3),
.page-version-logs-container .table-container table tbody td:nth-child(3) {
    text-align: center;
}

.page-version-logs-container .table-container table .info-icon {
    font-size: large;
}

    .page-version-logs-container .table-container table .info-icon:hover {
        color: #80ba54;
    }
