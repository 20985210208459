.form-form-container {
    text-align: center;
}

    .form-form-container .grid-wrapper {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .form-form-container .dual-grid {
        display: grid;
        grid-template-columns: 4fr 2fr;
        grid-gap: 5px;
        margin: 0 auto;
        width: 95%;
    }

    .form-form-container.form-form--update .dual-grid {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 5px;
        margin: 0 auto;
        width: 95%;
    }

    .form-form-container .dual-grid__1 {
        grid-column: 1;
    }

    .form-form-container .dual-grid__1-2 {
        grid-column: 1 /span 2;
    }

    .form-form-container .dual-grid-offset {
        display: grid;
        grid-template-columns: 2fr 4fr;
        grid-gap: 5px;
        max-width: 90%;
        margin: 0 auto;
        width: 60%;
    }

    .form-form-container.form-form--update .dual-grid-offset {
        grid-template-columns: 1fr 4fr;
        width: 95%;
    }

    .form-form-container .dual-grid-offset label {
        margin-top: 12px;
        text-align: left;
        padding-left: 30%;
        font-weight: bold;
    }

    .form-form-container.form-form--update .dual-grid-offset label {
        padding-left: 0;
    }

    .form-form-container .form-title {
        text-align: center;
    }

    .form-form-container .cancel-btn {
        background-color: #dd4b39;
        border-color: #da3a25;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        margin: 0 0 10px 10px;
        min-width: 50px;
        text-decoration: none;
    }

        .form-form-container .cancel-btn:hover,
        .form-form-container .cancel-btn:focus {
            background-color: #da3a25;
            border-color: #99291a;
        }

        .form-form-container .cancel-btn:active {
            background-color: #99291a;
            border-color: #57170f;
        }

        .form-form-container .cancel-btn:disabled {
            background-color: rgba(221, 75, 57, 0.5);
        }

    .form-form-container .form-button {
        background-color: #3c8dbc;
        border-color: #367fa9;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: white;
        padding: 12px 6px;
        font-weight: normal;
        font-size: 16px;
        /*margin: 0 0 10px 10px;*/
        min-width: 50px;
    }

        .form-form-container .form-button:hover,
        .form-form-container .form-button:focus {
            background-color: #367fa9;
            border-color: #204d74;
            text-decoration: none;
            outline: none;
        }

        .form-form-container .form-button:active {
            background-color: #204d74;
            border-color: #122b40;
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

        .form-form-container .form-button:disabled,
        .form-form-container .form-button--inactive {
            background-color: rgba(60, 141, 188, 0.5);
            cursor: default;
            pointer-events: none;
        }

    .form-form-container select,
    .form-form-container input {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .form-form-container .checkbox-container {
        display: inline-block;
        margin: auto;
        min-width: 33.3%;
    }

    .form-form-container .checkbox-wrapper {
        margin-right: 92%;
    }

    .form-form-container.form-form--update .checkbox-wrapper {
        margin-right: 95%;
    }

    .form-form-container .plain-text {
        margin-top: 12px;
        text-align: left;
        word-break: break-word;
    }

    .form-form-container .form-submit {
        margin-top: 5px;
    }
