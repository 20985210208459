#uploadImageBtn{
    text-align:center;
}

#imageContainer{
    width:210px;
    height:170px;
    padding:10px;
    float:left;
    cursor: pointer;
}

#imageContainerTop {
    width: 200px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center
}

#imageContainerDescription{
    text-align:center;
    height:30px;
}